import * as THREE from 'three'
// import * as SimplexNoise from 'simplex-noise'

import Particle from './Particle'
import ParticleSystem from './ParticleSystem'
import ParticleLine from './ParticleLine'

import * as colorFile from '../assets/textures/color4k.jpg'
import * as nightsFile from '../assets/textures/nights4k.jpg'
// import * as cloudsFile from '../assets/textures/clouds.jpg'
import * as sunlightFile from '../assets/textures/sunlight.jpg'
import * as rimlightFile from '../assets/textures/rimlight.jpg'

import * as dotGlowFile from '../assets/textures/dotGlow.jpg'
import * as dotLightFile from '../assets/textures/dotLight.jpg'

import { merge } from 'lodash-es'

import {
  DISPLAY_POI_DATA,
  CHANGE_LIVE_FILTERS,
  DISPLAY_TILESETS,
  UPDATE_TIMELAPSE,
  DISPLAY_TIMELAPSE,
  DISPLAY_TIMELAPSE_END,
  POIS_FOCUS_UPDATE,
  POI_SELECT,
  DISPLAY_LIVE,
  UPDATE_LIVE,
  earthRadius,
  mainColorArr,
  iAmHereColor } from '../config/constants'

import { makeVectorCoord } from '../utils/csv'

import { makeDbg } from '../utils/debug'

const dbg = makeDbg('3D:EarthObjects')

function sortDec (a, b) {
  return b - a
}

export default (scene, earthGroup, eventBus, loadingManager) => {
  const particles = []
  const dotGlowTex = new THREE.TextureLoader(loadingManager).load(dotGlowFile)
  const dotLightTex = new THREE.TextureLoader(loadingManager).load(dotLightFile)

  let totalTime = 0

  let angle = 0
  const earthPlaneSize = 260
  const earthGeometry = new THREE.SphereBufferGeometry(earthRadius, 30, 20)
  const sunlightGeometry = new THREE.SphereBufferGeometry(earthRadius + 0.1, 30, 20)
  const planeGeometry = new THREE.PlaneBufferGeometry(earthPlaneSize, earthPlaneSize, 1, 1)

  // color layer
  const colorTex = new THREE.TextureLoader(loadingManager).load(colorFile,)
  colorTex.wrapS = THREE.RepeatWrapping
  colorTex.wrapT = THREE.RepeatWrapping
  const colorMaterial = new THREE.MeshBasicMaterial({
    depthWrite: true,
    map: colorTex
  })
  const colorMesh = new THREE.Mesh(earthGeometry, colorMaterial)
  colorMesh.name = 'colorMesh'
  colorMesh.layers.set(1)
  earthGroup.add(colorMesh)

  // clouds layer
  /*
  const cloudsTex = new THREE.TextureLoader(loadingManager).load(cloudsFile)
  cloudsTex.wrapS = THREE.RepeatWrapping
  cloudsTex.wrapT = THREE.RepeatWrapping
  const cloudsMaterial = new THREE.MeshBasicMaterial({
    depthWrite: false,
    transparent: true,
    blending: THREE.AdditiveBlending,
    map: cloudsTex
  })
  const cloudsMesh = new THREE.Mesh(earthGeometry, cloudsMaterial)
  cloudsMesh.layers.set(1)
  earthGroup.add(cloudsMesh)

  const simplex = new SimplexNoise()
  */

  // sunlight layer
  const sunlightTex = new THREE.TextureLoader(loadingManager).load(sunlightFile)
  sunlightTex.wrapS = THREE.RepeatWrapping
  sunlightTex.wrapT = THREE.RepeatWrapping
  const sunlightMaterial = new THREE.MeshBasicMaterial({
    depthWrite: false,
    depthTest: false,
    blending: THREE.MultiplyBlending,
    map: sunlightTex
  })
  const sunlightMesh = new THREE.Mesh(sunlightGeometry, sunlightMaterial)
  sunlightMesh.layers.set(2)
  earthGroup.add(sunlightMesh)

  // nights layer
  const nightsTex = new THREE.TextureLoader(loadingManager).load(nightsFile)
  nightsTex.wrapS = THREE.RepeatWrapping
  nightsTex.wrapT = THREE.RepeatWrapping
  const nightsMaterial = new THREE.MeshBasicMaterial({
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    map: nightsTex,
    opacity: 0.5
  })
  const nightsMesh = new THREE.Mesh(earthGeometry, nightsMaterial)
  nightsMesh.layers.set(3)
  earthGroup.add(nightsMesh)

  // rimlight layer
  const rimlightTex = new THREE.TextureLoader(loadingManager).load(rimlightFile)
  rimlightTex.wrapS = THREE.RepeatWrapping
  rimlightTex.wrapT = THREE.RepeatWrapping
  const rimlightMaterial = new THREE.MeshBasicMaterial({
    depthWrite: false,
    depthTest: THREE.NeverDepth,
    blending: THREE.AdditiveBlending,
    map: rimlightTex
  })
  const rimlightMesh = new THREE.Mesh(planeGeometry, rimlightMaterial)
  rimlightMesh.layers.set(3)
  earthGroup.add(rimlightMesh)

  sunlightMesh.rotation.x = 0
  sunlightMesh.rotation.y = 0
  sunlightMesh.rotation.z = 0

  let sunDestination = 1
  let useSunDestination = false
  let sunSpeed = 0.0

  let speedFade = 0
  const earthSpeedMax = -0.2
  let earthSpeed = earthSpeedMax

  const dotGroup = new THREE.Group()
  earthGroup.add(dotGroup)

  // const iAmHereParticle = Particle(dotGroup, [0, 0, 0], iAmHereColor, iAmHereColor, iAmHereColor, 0.1, dotGlowTex, dotLightTex)
  const iAmHereParticle = ParticleLine(dotGroup, [0, 0, 0], iAmHereColor, iAmHereColor, iAmHereColor, 0.1, new THREE.Vector3(0, 0, 0))
  iAmHereParticle.setMaxOpacity(0)
  particles.push(iAmHereParticle)

  let lastPoiUpdate = 0
  let poiFocus = false
  const raycaster = new THREE.Raycaster()

  const poiList = []
  let poiParticleList = {}
  const poiFocusedIdList = []
  let lastPOISelectionID = -1

  const secondsToHours = 1.0 / 3600.0

  let liveFilters = []
  let liveDisplay = false

  let liveAuxIds = {}
  let currentLiveDataValid = false
  let currentLiveData = {}
  let timelapseData = {}
  let loopTimelapse = true
  let liveTimelapse = false
  let liveTimelapsePause = false
  let liveTimelapseTime = 0
  let liveTimelapseBaseSpeed = 5.0
  let liveTimelapseSpeed = 0.01
  let allTimelapseData = {}
  const allTimelapseTimestamps = []
  const liveTilesetIds = []
  let liveParticleList = {}
  let liveParticleTilesetList = {}
  let minTimestamp = 0
  let totalTimestampDuration = 0
  let totalTimestampDurationFactor = 1.0

  const focusLineOffset = new THREE.Vector3(0, 0, 0)
  let showFocusLines = false
  const focusLines = [
    ParticleLine(dotGroup, [0, 0, 0], iAmHereColor, iAmHereColor, iAmHereColor, 2, focusLineOffset),
    ParticleLine(dotGroup, [0, 0, 0], iAmHereColor, iAmHereColor, iAmHereColor, 2, focusLineOffset),
    ParticleLine(dotGroup, [0, 0, 0], iAmHereColor, iAmHereColor, iAmHereColor, 2, focusLineOffset),
    ParticleLine(dotGroup, [0, 0, 0], iAmHereColor, iAmHereColor, iAmHereColor, 2, focusLineOffset)
  ]
  for (let num = 0; num < focusLines.length; num++) {
    const focusLine = focusLines[num]
    focusLine.setMaxOpacity(0)
    particles.push(focusLine)
  }

  eventBus.subscribe(DISPLAY_TILESETS, arg => {
    poiFocus = false
    liveTimelapse = false
    setSpeedFade(0.05)
    showTilesetData(arg)
    dbg(DISPLAY_TILESETS)
  })

  eventBus.subscribe(UPDATE_LIVE, uTs => {
    const currArr = merge(currentLiveData, uTs)
    currentLiveData = currArr
    currentLiveDataValid = true
    dbg(UPDATE_LIVE, currArr)
    if (liveDisplay && currentLiveDataValid) {
      showTilesetData(currentLiveData)
    }
  })

  eventBus.subscribe(DISPLAY_LIVE, arg => {
    poiFocus = false
    liveTimelapse = false
    liveDisplay = true
    setSpeedFade(0.05)
    if (liveDisplay && currentLiveDataValid) {
      showTilesetData(currentLiveData)
    }
    dbg(DISPLAY_LIVE)
  })

  eventBus.subscribe(UPDATE_TIMELAPSE, arg => {
    timelapseData = arg
    dbg(UPDATE_TIMELAPSE)
  })

  eventBus.subscribe(CHANGE_LIVE_FILTERS, arg => {
    if (arg.hasOwnProperty('active')) {
      liveFilters = arg.active
    }
    if (arg.hasOwnProperty('aux')) {
      liveAuxIds = arg.aux
    }
    dbg(CHANGE_LIVE_FILTERS, arg)
    if (liveTimelapse || liveDisplay) {
      liveFilterOpacity()
    }
  })

  eventBus.subscribe(DISPLAY_TIMELAPSE, arg => {
    if (arg) {
      liveTimelapsePause = false
      if (!liveTimelapse) {
        poiFocus = false
        liveTimelapse = true
        liveDisplay = false
        setSpeedFade(0.05)
        showTimelapseData(timelapseData)
      }
    } else {
      liveTimelapsePause = true
      poiFocus = false
      liveTimelapse = false
      liveDisplay = true
      setSpeedFade(0.05)
      if (liveDisplay && currentLiveDataValid) {
        showTilesetData(currentLiveData)
      }
    }
    dbg(DISPLAY_TIMELAPSE, arg)
  })

  eventBus.subscribe(DISPLAY_POI_DATA, arg => {
    poiFocus = true
    liveTimelapse = false
    liveDisplay = false
    setSpeedFade(0.0)
    showPOIData(arg)
    dbg(DISPLAY_POI_DATA)
  })
  eventBus.subscribe(POI_SELECT, arg => {
    dbg(POI_SELECT, arg)
    poiList.forEach(poiData => {
      if (poiData.id in poiParticleList) {
        poiParticleList[poiData.id].setSelected(false)
      }
    })
    if (arg in poiParticleList) {
      poiParticleList[arg].setSelected(true)
    }
  })

  const now = new Date().getTime() / 1000 // seconds
  const hour = now / 36000.0
  setSunTime(hour, 0)

  function angleRepeat (val) {
    val += 180.0
    if (val < 0) {
      val += 360.0
    } else if (val >= 360.0) {
      val -= 360.0
    }
    val -= 180.0
    return val
  }

  function liveFilterOpacity () {
    for (const key in liveTilesetIds) {
      const tilesetId = liveTilesetIds[key]
      let visible = false
      if (liveFilters.includes(tilesetId)) {
        visible = true
      }
      for (const part in liveParticleTilesetList[tilesetId]) {
        const particle = liveParticleTilesetList[tilesetId][part]
        particle.setVisible(visible)
      }
      dbg('liveFilterOpacity', tilesetId, visible, liveParticleTilesetList[tilesetId].length)
    }
  }

  function clearData () {
    liveTimelapseTime = 0
    allTimelapseTimestamps.length = 0
    liveTilesetIds.length = 0
    allTimelapseData = {}
    liveParticleList = {}
    liveParticleTilesetList = {}
    poiList.length = 0
    poiParticleList = {}
  }

  function showTimelapseData (arg) {
    clearParticles()
    clearData()
    for (const key in arg) {
      const fullData = arg[key]
      // dbg('fullData', fullData)
      for (const key in fullData) {
        const mainData = fullData[key].data
        // dbg('mainData', mainData)
        for (const key in mainData) {
          if (mainData.hasOwnProperty(key)) {
            const liveMainData = mainData[key]
            // dbg('liveMainData', liveMainData)
            if (!liveTilesetIds.includes(liveMainData.id)) {
              liveTilesetIds.push(liveMainData.id)
              liveParticleTilesetList[liveMainData.id] = []
            }
            let lid = liveMainData.id % 3
            if (liveAuxIds.hasOwnProperty(liveMainData.id)) {
              lid = (liveAuxIds[liveMainData.id] - 1) % 3
            }
            let color = mainColorArr[0]
            if (lid === 1) {
              color = color = mainColorArr[1]
            } else if (lid === 2) {
              color = color = mainColorArr[2]
            }
            if (liveMainData.hasOwnProperty('data')) {
              for (const key in liveMainData.data) {
                const liveDataList = liveMainData.data[key]
                // dbg('liveDataList', liveDataList)
                for (const key in liveDataList) {
                  const liveData = liveDataList[key]
                  liveData.createdAt = Number(liveData.createdAt)
                  if (!liveParticleList.hasOwnProperty(liveData.tileId)) {
                    let extraLength = liveData.relativeCount
                    const vector = makeVectorCoord(liveData.latitudeAvg, liveData.longitudeAvg)
                    const particle = Particle(dotGroup, vector, color, color, color, extraLength, dotGlowTex, dotLightTex)
                    // const particle = ParticleLine(dotGroup, vector, color, color, color, extraLength, new THREE.Vector3(0, 0, 0))
                    particle.animatePulse = false
                    particle.setMaxOpacity(1)
                    particle.setColorAnimated(false)
                    liveParticleList[liveData.tileId] = particle
                    liveParticleTilesetList[liveMainData.id].push(particle)
                    particles.push(particle)
                    // const hour = liveData.createdAt * secondsToHours
                    // setSunTime(hour, 0)
                  }
                  if (!allTimelapseData.hasOwnProperty(liveData.createdAt)) {
                    allTimelapseData[liveData.createdAt] = []
                    allTimelapseTimestamps.push(liveData.createdAt)
                  }
                  allTimelapseData[liveData.createdAt].push(liveData)
                }
              }
            }
          }
        }
      }
    }
    allTimelapseTimestamps.sort()
    minTimestamp = allTimelapseTimestamps[0]
    totalTimestampDuration = allTimelapseTimestamps[allTimelapseTimestamps.length - 1] - minTimestamp
    if (totalTimestampDuration > 0) {
      totalTimestampDurationFactor = 1.0 / totalTimestampDuration
      liveTimelapseSpeed = liveTimelapseBaseSpeed / (totalTimestampDuration / 1000.0)
    } else {
      totalTimestampDurationFactor = 1.0
      liveTimelapseSpeed = 0.01
    }
    liveFilterOpacity()
    // dbg('minTimestamp', minTimestamp, totalTimestampDuration, liveTimelapseSpeed)
    dbg('particles count', particles.length)
    // dbg('liveTilesetIds', liveTilesetIds)
  }

  function updateLiveTimelapse () {
    const currentTimestampFloat = liveTimelapseTime * totalTimestampDuration + minTimestamp
    let currentTimestamp = 0
    let timeIndex = 0
    let timeFade = 0
    for (let num = 0; num < allTimelapseTimestamps.length; num++) {
      const timestamp = allTimelapseTimestamps[num]
      if (timestamp < currentTimestampFloat) {
        currentTimestamp = timestamp
        timeIndex = num
      } else {
        break
      }
    }
    // dbg(liveTimelapseTime, currentTimestampFloat, currentTimestamp)
    const hour = currentTimestampFloat * secondsToHours
    setSunTime(hour, 0)
    const usedParticles = []
    if (allTimelapseData.hasOwnProperty(currentTimestamp)) {
      const currentDataList = allTimelapseData[currentTimestamp]
      const nextTimestamp = allTimelapseTimestamps[timeIndex + 1]
      if (allTimelapseData.hasOwnProperty(nextTimestamp)) {
        const nextDataList = allTimelapseData[nextTimestamp]
        timeFade = (nextTimestamp - currentTimestamp) * totalTimestampDurationFactor
        for (let num = 0; num < currentDataList.length; num++) {
          const currentData = currentDataList[num]
          const nextDataFiltered = nextDataList.filter(function (liveData) {
            return liveData.tileId === currentData.tileId
          })
          const particle = liveParticleList[currentData.tileId]
          usedParticles.push(currentData.tileId)
          let lon = currentData.longitudeAvg
          let lat = currentData.latitudeAvg
          let extraLength = currentData.relativeCount
          // let age = currentData.ageAvg
          if (nextDataFiltered.length > 0) {
            const nextData = nextDataFiltered[0]
            lon = lerp(currentData.longitudeAvg, nextData.longitudeAvg, timeFade)
            lat = lerp(currentData.latitudeAvg, nextData.latitudeAvg, timeFade)
            extraLength = lerp(currentData.relativeCount, nextData.relativeCount, timeFade)
            // age = lerp(currentData.ageAvg, nextData.ageAvg, timeFade)
          }
          particle.setMaxOpacity(1)
          particle.setExtraLength(extraLength)
          particle.setLatLon(lon, lat)
          /*
          if (age > 20000) {
            particle.setHover(false)
            particle.setSelected(false)
          } else if (age > 10000) {
            particle.setHover(true)
            particle.setSelected(false)
          } else {
            particle.setHover(false)
            particle.setSelected(true)
          }
          */
        }
      }
    }
    for (const key in liveParticleList) {
      if (liveParticleList.hasOwnProperty(key) && (!usedParticles.includes(parseInt(key)))) {
        const particle = liveParticleList[key]
        particle.setMaxOpacity(0)
      }
    }
  }

  function showPOIData (arg) {
    clearParticles()
    clearData()
    for (const key in arg) {
      const poiData = arg[key]
      let color = mainColorArr[2]
      let colorHover = mainColorArr[2]
      let colorSelected = mainColorArr[2]
      let extraLength = 0.05 + THREE.Math.randFloatSpread(0.6)
      let defaultOpacity = 0.25
      if (poiData.hasDetails) {
        color = mainColorArr[1]
        colorHover = mainColorArr[0]
        colorSelected = mainColorArr[1]
        extraLength = 0.5
        defaultOpacity = 0.75
      }
      poiList.push(poiData)
      const vector = makeVectorCoord(poiData.latitude, poiData.longitude)
      const particle = ParticleLine(dotGroup, vector, color, colorHover, colorSelected, extraLength, new THREE.Vector3(0, 0, 0))
      // const particle = Particle(dotGroup, vector, color, colorHover, colorSelected, extraLength, dotGlowTex, dotLightTex)
      particle.setDefaultOpacity(defaultOpacity)
      poiParticleList[poiData.id] = particle
      particle.setMaxOpacity(1)
      particles.push(particle)
    }
  }

  function selectPOI (arg) {
    if (lastPOISelectionID in poiParticleList) {
      poiParticleList[lastPOISelectionID].setSelected(false)
    }
    if (arg.id in poiParticleList) {
      poiParticleList[arg.id].setSelected(true)
      lastPOISelectionID = arg.id
    }
  }

  function showTilesetData (arg) {
    clearParticles()
    clearData()
    if (arg.hasOwnProperty('data')) {
      if (arg.data.length > 1) {
        for (let num = 0; num < arg.data.length; num++) {
          const tilesetData = arg.data[num]
          if (tilesetData.hasOwnProperty('id')) {
            if (!liveTilesetIds.includes(tilesetData.id)) {
              liveTilesetIds.push(tilesetData.id)
              liveParticleTilesetList[tilesetData.id] = []
            }
          }
          if (tilesetData.hasOwnProperty('data')) {
            buildParticles(tilesetData.id, tilesetData.data, tilesetData.id)
          } else {
            buildParticleLines(num, tilesetData, -1)
          }
          dbg('tilesetData', num, tilesetData)
        }
      } else {
        const tilesetData = arg.data[0]
        buildParticleLines(0, tilesetData, -1)
      }
    }
    liveFilterOpacity()
    dbg('particles count', particles.length)
  }

  function buildParticleLines (id, data, tilesetId) {
    let color = mainColorArr[0]
    let offset = new THREE.Vector3(0, 0, 0)
    let lid = id % 3
    /* if (liveAuxIds.hasOwnProperty(id)) {
      lid = (liveAuxIds[id] - 1) % 3
    } */
    if (lid === 1) {
      color = mainColorArr[1]
      offset = new THREE.Vector3(-0.1, 0, -0.1)
    } else if (lid === 2) {
      color = mainColorArr[2]
      offset = new THREE.Vector3(0.1, 0, 0.1)
    }
    for (const key in data) {
      const particleData = data[key]
      const vector = makeVectorCoord(particleData.latitudeAvg, particleData.longitudeAvg)
      const particle = ParticleLine(dotGroup, vector, color, color, color, particleData.relativeCount, offset)
      particle.setMaxOpacity(1)
      particle.setColorAnimated(false)
      particles.push(particle)
      if (tilesetId >= 0) {
        liveParticleTilesetList[tilesetId].push(particle)
      }
    }
  }

  function buildParticles (id, data, tilesetId) {
    let color = mainColorArr[0]
    let lid = tilesetId % 3
    if (liveAuxIds.hasOwnProperty(tilesetId)) {
      lid = (liveAuxIds[tilesetId] - 1) % 3
    }
    if (lid === 1) {
      color = mainColorArr[1]
    } else if (lid === 2) {
      color = mainColorArr[2]
    }
    for (const key in data) {
      const particleData = data[key]
      const vector = makeVectorCoord(particleData.latitudeAvg, particleData.longitudeAvg)
      const particle = Particle(dotGroup, vector, color, color, color, particleData.relativeCount, dotGlowTex, dotLightTex)
      particle.setMaxOpacity(1)
      particles.push(particle)
      if (tilesetId >= 0) {
        liveParticleTilesetList[tilesetId].push(particle)
      }
    }
  }

  function buildParticleSystem (id, data) {
    const positionsA = []
    const positionsB = []
    const positionsC = []
    const positionsD = []
    const positionsE = []
    // let updateSun = true
    for (const key in data) {
      const particleData = data[key]
      let cnt = particleData.relativeCount
      if (cnt > 0.25) {
        positionsA.push(new THREE.Vector2(particleData.latitudeAvg, particleData.longitudeAvg))
      } else if (cnt > 0.1) {
        positionsB.push(new THREE.Vector2(particleData.latitudeAvg, particleData.longitudeAvg))
      } else if (cnt > 0.05) {
        positionsC.push(new THREE.Vector2(particleData.latitudeAvg, particleData.longitudeAvg))
      } else if (cnt > 0.025) {
        positionsD.push(new THREE.Vector2(particleData.latitudeAvg, particleData.longitudeAvg))
      } else {
        positionsE.push(new THREE.Vector2(particleData.latitudeAvg, particleData.longitudeAvg))
      }
    }
    const colorA = mainColorArr[2]
    const colorB = mainColorArr[2]
    const colorC = mainColorArr[1]
    const colorD = mainColorArr[1]
    const colorE = mainColorArr[0]
    addParticleSystem(positionsA, colorA, 20, 1)
    addParticleSystem(positionsB, colorB, 6, 2)
    addParticleSystem(positionsC, colorC, 4, 3)
    addParticleSystem(positionsD, colorD, 1, 4)
    addParticleSystem(positionsE, colorE, 0, 5)
  }

  function addParticleSystem (positions, color, extraSize, pulseOffset) {
    const particleSystem = ParticleSystem(dotGroup, positions, pulseOffset, color, extraSize, dotGlowTex)
    particles.push(particleSystem)
    return particleSystem
  }

  function clearParticles () {
    if (particles.length > 0) {
      for (const key in particles) {
        const oldParticle = particles[key]
        if ((oldParticle !== iAmHereParticle) && (!focusLines.includes(oldParticle))) {
          oldParticle.remove()
        }
      }
    }
  }

  function setSunTime (gmt, newSunSpeed) {
    if (!gmt) gmt = 0
    sunDestination = ((gmt + 12.0) % 24) / 24.0
    useSunDestination = true
    sunSpeed = newSunSpeed
  }

  function update (deltaTime, camera, zoom) {
    totalTime += deltaTime

    if (useSunDestination) {
      let dest = sunDestination
      sunlightTex.offset.x = dest % 1
    } else if (sunSpeed > 0) {
      sunlightTex.offset.x = (sunlightTex.offset.x + sunSpeed * deltaTime) % 1
    }
    earthSpeed = lerp(earthSpeed, lerp(0, earthSpeedMax, speedFade), deltaTime * 10)
    angle += deltaTime * earthSpeed
    earthGroup.rotation.y = angle
    // cloudsTex.offset.x = simplex.noise2D(angle * 0.6, 0) * 0.01
    for (let num = (particles.length - 1); num >= 0; num--) {
      const particle = particles[num]
      particle.update(deltaTime)
      if (particle.isParticleRemoved()) {
        particles.splice(num, 1)
      }
    }
    rimlightMesh.lookAt(camera.position)
    let showNow = false
    if (poiFocus) {
      showNow = true
      if (totalTime > lastPoiUpdate + 0.1) {
        testPOIFocus(camera, zoom)
        lastPoiUpdate = totalTime
      }
    } else if (liveTimelapse) {
      if (!liveTimelapsePause) {
        liveTimelapseTime += deltaTime * liveTimelapseSpeed
        if (liveTimelapseTime < 1.0) {
          updateLiveTimelapse()
        } else {
          if (loopTimelapse) {
            liveTimelapseTime -= 1.0
            updateLiveTimelapse()
            dbg('loopTimelapse')
          } else {
            liveTimelapse = false
            eventBus.publish(DISPLAY_TIMELAPSE_END, true)
            dbg(DISPLAY_TIMELAPSE_END)
          }
        }
      }
    } else {
      showNow = true
    }
    if (showNow) {
      const now = new Date().getTime() / 1000.0 // seconds
      const hour = now * secondsToHours
      setSunTime(hour, 0)
    }
  }

  const minPOIFocusRange = 2
  const maxPOIFocusRange = 10
  let over = false
  function testPOIFocus (camera, zoom) {
    const vx = (window.POI_FOCUS_X / window.innerWidth) * 2 - 1
    const vy = (window.POI_FOCUS_Y / window.innerHeight) * 2 - 1
    const rayOrigin = new THREE.Vector2(vx, -vy)
    raycaster.setFromCamera(rayOrigin, camera)
    const intersects = raycaster.intersectObject(colorMesh)
    let changed = false
    if (over && (intersects.length === 0)) {
      over = false
      // dbg('focus exit earth')
      poiList.forEach(poiData => {
        if (poiData.id in poiParticleList) {
          poiParticleList[poiData.id].setHover(false)
        }
      })
      if (showFocusLines) {
        for (let num = 0; num < focusLines.length; num++) {
          const focusLine = focusLines[num]
          focusLine.setMaxOpacity(0)
        }
      }
      eventBus.publish(POIS_FOCUS_UPDATE, [])
    } else if ((intersects.length > 0) && (!over)) {
      over = true
      // dbg('focus over earth', intersects[0])
    }
    if (over) {
      // dbg('focus test ' + vx + 'x' + vy + ', uv ' + intersects[0].uv.x + 'x' + intersects[0].uv.y)
      const minLat = intersects[0].uv.x * 360.0 - 180.0
      const minLon = intersects[0].uv.y * 180.0 - 90
      const range = lerp(minPOIFocusRange, maxPOIFocusRange, zoom)
      const filteredPoi = poiList.filter(function (poiData) {
        let insideFocus = false
        insideFocus = (
          (angleRepeat(poiData.longitude) > angleRepeat(minLat - range)) && (angleRepeat(poiData.longitude) < angleRepeat(minLat + range)) &&
          (angleRepeat(poiData.latitude) > angleRepeat(minLon - range)) && (angleRepeat(poiData.latitude) < angleRepeat(minLon + range))
        )
        if (poiData.id in poiParticleList) {
          poiParticleList[poiData.id].setHover(insideFocus)
        }
        return insideFocus
      })
      const usedKeys = []
      for (const key in filteredPoi) {
        const poiData = filteredPoi[key]
        if (!poiFocusedIdList.includes(poiData.id)) {
          // new value...
          changed = true
          if (poiData.hasDetails === 0) {
            poiFocusedIdList.push(poiData.id)
          } else {
            poiFocusedIdList.unshift(poiData.id)
          }
        }
        usedKeys.push(poiData.id)
      }
      for (let num = poiFocusedIdList.length - 1; num >= 0; num--) {
        const oldKey = poiFocusedIdList[num]
        if (!usedKeys.includes(oldKey)) {
          // lost a value...
          changed = true
          poiFocusedIdList.splice(num, 1)
        }
      }
      if (showFocusLines) {
        for (let num = 0; num < focusLines.length; num++) {
          const focusLine = focusLines[num]
          focusLine.setMaxOpacity(1)
          switch (num) {
            case 1:
              focusLine.setLatLon(minLat - range, minLon - range)
              break
            case 2:
              focusLine.setLatLon(minLat + range, minLon - range)
              break
            case 3:
              focusLine.setLatLon(minLat - range, minLon + range)
              break
            default:
              focusLine.setLatLon(minLat + range, minLon + range)
              break
          }
        }
      }
      if (changed) {
        // poiFocusedIdList.sort(sortDec)
        dbg('focus keys changed', poiFocusedIdList)
        eventBus.publish(POIS_FOCUS_UPDATE, poiFocusedIdList)
      }
    }
  }

  function setSpeedFade (newSpeedFade) {
    speedFade = newSpeedFade
  }

  function iAmHere (lat, lon) {
    iAmHereParticle.setLatLon(lat, lon)
    iAmHereParticle.setMaxOpacity(1)
  }

  function lerp (v0, v1, t) {
    return (1.0 - t) * v0 + t * v1
  }

  return {
    update,
    setSpeedFade,
    iAmHere,
    selectPOI
  }
}
