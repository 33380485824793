import React from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import {poiTimeTable} from '../../theme/typo'
const OpeningContainers = styled.div`
  display: flex;
  ${poiTimeTable}
  margin-bottom: 20px;
  ${({ theme }) => theme.small`flex-flow: column nowrap`};
`
const TitleCont = styled.div`
  /* background: rgba(200,200,200,.2); */
  flex:2;
`
const HoursCont = styled.div`
  /* background: rgba(200,200,200,.8); */

  flex:3;
`

const HourItem = styled.div`
  flex:1
  ${({isOdd}) => isOdd && css`text-align: right`}
`
const HourRow = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.filterBgGrey};
  ${({isOdd}) => isOdd && css`background:transparent`}
`

const OpeningHours = (props) => {
  return (
    <OpeningContainers>
      <TitleCont>
        {props.title}
      </TitleCont>
      <HoursCont>
        {/* <Fragment> */}
        {props.rows.map((row, i) => {
          return <HourRow key={`row-${i}`} isOdd={i % 2 !== 0}>
            {row.map((item, i) => {
              return <HourItem key={`item-${i}`} isOdd={i % 2 !== 0}>{item}</HourItem>
            })}
          </HourRow>
        })}
        {/* </Fragment> */}
      </HoursCont>
    </OpeningContainers>
  )
}

OpeningHours.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
}

OpeningHours.defaultProps = {

}

export default OpeningHours
