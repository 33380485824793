import {
  GEOLOC_CHECK,
  GEOLOC_SUCCESS,
  GEOLOC_FAILURE,
  UPDATE_CLIENT_ID,
  UPDATE_CLIENT_ID_SUCCESS,
  UPDATE_CLIENT_ID_FAILURE,
} from '../../config/constants'

export function geolocStartAction () {
  return {
    type: GEOLOC_CHECK
  }
}

export const geolocSuccessAction = (long, lat) => {
  updateClientIdStartAction()
  return {
    type: GEOLOC_SUCCESS,
    long,
    lat
  }
}

export function geolocFailureAction () {
  return {
    type: GEOLOC_FAILURE
  }
}
export function updateClientIdStartAction () {
  return {
    type: UPDATE_CLIENT_ID
  }
}

export function updateClientIdSuccessAction (id) {
  return {
    type: UPDATE_CLIENT_ID_SUCCESS,
    id
  }
}

export function updateClientIdFailureAction (err) {
  return {
    type: UPDATE_CLIENT_ID_FAILURE,
    err
  }
}
