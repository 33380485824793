import React from 'react'
import styled from 'styled-components'
import prrtraitpct from '../../assets/images/portraitMode_info.svg'
// import PropTypes from 'prop-types'

const WarnCont = styled.div`
  background: #fecd0f;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LandscapeWarning = (props) => {
  return (
    <WarnCont>
      <img src={prrtraitpct} alt='please use portrait mode to use this site' />
    </WarnCont>
  )
}

LandscapeWarning.propTypes = {

}

LandscapeWarning.defaultProps = {

}

export default LandscapeWarning
