
import * as debug from 'debug'
export function makeDbg (name = 'dbg', appName = 'yana') {
  const dbgName = `${appName}:${name}`
  const debugInst = debug(dbgName)
  return function () {
    if (process.env.NODE_ENV !== 'development') return
    debugInst(...arguments)
  }
}
