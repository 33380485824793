import Papaparse from 'papaparse'
import {earthRadius, earthRadiusOffset} from '../config/constants'
import {makeDbg} from '../utils/debug'
const dbg = makeDbg('UTILS:CSV')

export function parse (data) {
  // dbg('start parsing data', data)
  const dataObj = {}
  const csvData = Papaparse.parse(data)

  csvData.data.map((line) => {
    if (line[0]) {
      dataObj[line[0]] = formatdata(line)
      return true
    } else return false
  })

  return dataObj
}

export const parseHistory = function (data) {
  // dbg('start parsing history data')
  const dataObj = {}
  const conf = {delimiter: ';'}
  // const conf = {}
  const csvData = Papaparse.parse(data, conf)
  // dbg('data parsed', csvData)
  if (csvData.errors.length) {
    dbg('parsing error')
    dbg(data)
    dbg(csvData.errors)
  }

  csvData.data.map((line) => {
    if (line[0] && line.length === 7) {
      const formated = formatdata(line)
      // console.log(line)
      if (!dataObj[line[0]]) dataObj[line[0]] = []
      dataObj[line[0]].push(formated)
      return true
    } else {
      if (line[0] !== '') {
        dbg('irregular line parsed')
        dbg(line.length)
        dbg(line)
      }
      return false
    }
  })
  return dataObj
}

export function makeVectorCoord (lon, lat) {
  const u = (lon * Math.PI) / 180
  const w = ((lat - 180) * Math.PI) / 180
  const x = -(earthRadius + earthRadiusOffset) * Math.cos(u) * Math.cos(w)
  const y = (earthRadius + earthRadiusOffset) * Math.sin(u)
  const z = (earthRadius + earthRadiusOffset) * Math.cos(u) * Math.sin(w)
  return [x, y, z]
}

function formatdata (data) {
  return {
    tileId: parseInt(data[0]),
    createdAt: parseInt(data[1]),
    presenceCount: parseInt(data[2]),
    relativeCount: parseFloat(data[3]),
    longitudeAvg: parseFloat(data[4]),
    latitudeAvg: parseFloat(data[5]),
    ageAvg: parseInt(data[6]),
    vector: makeVectorCoord(parseFloat(data[4]), parseFloat(data[5]))
  }
}
