import SceneManager from './SceneManager'

import {GEOLOC_SEND_UPDATE} from '../config/constants'

export default (container, eventBus) => {
  const canvas = createCanvas(document, container)
  const sceneManager = new SceneManager(canvas, eventBus)

  bindEventListeners()
  render()

  function createCanvas (document, container) {
    const canvas = document.createElement('canvas')
    container.appendChild(canvas)
    return canvas
  }

  function bindEventListeners () {
    window.onresize = resizeCanvas
    window.onwheel = onwheel
    resizeCanvas()
  }

  function resizeCanvas () {
    canvas.style.width = '100%'
    canvas.style.height = '100%'

    canvas.width = canvas.offsetWidth
    canvas.height = canvas.offsetHeight

    // console.info('canvas ' + canvas.offsetWidth + 'x' + canvas.offsetHeight)

    sceneManager.onWindowResize()
  }

  function iAmHere (lat, lon) {
    sceneManager.iAmHere(lon, lat)
  }

  function onwheel (event) {
    // sceneManager.onWheel(event)
  }

  function render (time) {
    requestAnimationFrame(render)
    sceneManager.update(time)
  }

  eventBus.subscribe(GEOLOC_SEND_UPDATE, (loc) => {
    iAmHere(...loc)
  })

  return sceneManager
}
