import {
  SET_FIXED_ID,
} from '../../config/constants'

export function setFixedRadioIdAction (id) {
  return {
    type: SET_FIXED_ID,
    id
  }
}
