import styled from 'styled-components'
import posed from 'react-pose'
import targetPct from '../../assets/images/focus.png'
export const Container = posed(styled.div`
color:#fff;
font-size: 3rem;
display:flex;
height:100%;
overflow: hidden;
`)({
  enter: { staggerChildren: 50 }
})

export const ContentCont = styled.div`
/* background: red; */
position: relative;
flex: 1;
`

export const Focus = posed(styled.img`
  width: 97px;
  height: 97px;
  position: absolute;
  z-index: 15;
`)({
  draggable: true,
})

Focus.defaultProps = {
  src: targetPct
}

export const SmallOverlay = posed(styled.div`
background: rgba(0, 0 , 0, .9);
position: absolute;
top: 0;
bottom: 0;
right: 0;
left: 0;
z-index: 19;
`)({
  opened: {
    x: 0,
    transition: {
      default: {
        duration: 300,
        type: 'tween',
        ease: 'easeIn' }
    }
  },
  closed: {
    x: '-100%',
    transition: {
      default: {
        duration: 300,
        type: 'tween',
        ease: 'easeIn' }
    }
  },
})

export const LocationsListCont = styled.div`
  position: absolute;
  z-index: 14;
  top:45%;
  margin-left: 15px;
  ${({ theme }) => theme.small`
    top: auto;
    bottom: 160px;
    margin-left: 5px;
  `}
  /* bottom: 0; */
`

export const Drawer = posed(styled.div`
background: ${({theme}) => theme.colors.poiInfoBg};
height: 100%;
width: 600px;
position: absolute;
right: 0;
z-index: 19;
`)({
  opened: {
    x: 0,
    transition: {
      default: {
        duration: 300,
        type: 'tween',
        ease: 'easeIn' }
    }
  },
  closed: {
    x: '100%',
    transition: {
      default: {
        duration: 300,
        type: 'tween',
        ease: 'easeIn' }
    }
  },
})
