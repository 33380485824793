import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize' // import normalize.css

import DeliveryLghtIt from '../assets/fonts/Delivery_W_LtIt.woff'
import DeliveryLght from '../assets/fonts/Delivery_W_Lt.woff'
import DeliveryRegIt from '../assets/fonts/Delivery_W_It.woff'
import DeliveryReg from '../assets/fonts/Delivery_W_Rg.woff'
import DeliveryBold from '../assets/fonts/Delivery_W_Bd.woff'
import DeliveryBoldIt from '../assets/fonts/Delivery_W_BdIt.woff'
import DeliveryBlk from '../assets/fonts/Delivery_W_CdBlk.woff'
import DeliveryCndL from '../assets/fonts/Delivery_W_CdLt.woff'

export default createGlobalStyle`
  ${styledNormalize}
  /* Light */
  @font-face {
    font-family: Delivery, sans-serif;
    font-weight: 200;
    src: url('${DeliveryLghtIt}') format('woff');
    font-style: italic, oblique;
  }
  @font-face {
    font-family: Delivery;
    font-weight: 200;
    src: url('${DeliveryLght}') format('woff');
  }
  /* reg */
  @font-face {
    font-family: Delivery;
    font-weight: 400;
    src: url('${DeliveryRegIt}') format('woff');
    font-style: italic, oblique;
  }
  @font-face {
    font-family: Delivery;
    font-weight: 400;
    src: url('${DeliveryReg}') format('woff');
  }
  @font-face {
    font-family: Delivery;
    font-weight: 500;
    src: url('${DeliveryCndL}') format('woff');
  }
  /* Bold */
  @font-face {
    font-family: Delivery;
    font-weight: 700;
    src: url('${DeliveryBoldIt}') format('woff');
    font-style: italic, oblique;
  }
  @font-face {
    font-family: Delivery;
    font-weight: 700;
    src: url('${DeliveryBold}') format('woff');
  }
  /* Black */
  @font-face {
    font-family: Delivery;
    font-weight: 800;
    src: url('${DeliveryBlk}') format('woff');
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%; /* =10px = 1rem through the app */
    font-family: Delivery, sans-serif;
    background: #000;
    color: #fff;

  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  html, body{
    margin: 0;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *:focus {
    outline: none;
  }

  ::-webkit-scrollbar {
      display: none;
  }

  h1, h2, h3, h4, h5{
    margin-top: 0;
  }

  /* reset */
  /* bt */
  [role="button"],
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button {
      -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
              box-sizing: content-box;
  }

  body, html, #root{
    min-height: 100%;
    height: 100%;
  }

  /*#root{
    top:0;
    bottom:0;
    left:0;
    right: 0;
    overflow: hidden;
  }*/

  /* Reset 'button' and button-style 'input' default styles */
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button {
      background: none;
      border: 0;
      color: inherit;
      /* cursor: default; */
      font: inherit;
      line-height: normal;
      overflow: visible;
      padding: 0;
      -webkit-appearance: button; /* for input */
      -webkit-user-select: none; /* for button */
        -moz-user-select: none;
          -ms-user-select: none;
  }
  input::-moz-focus-inner,
  button::-moz-focus-inner {
      border: 0;
      padding: 0;
      color: teal
  }

  /* Make 'a' like a button */
  [role="button"] {
      color: inherit;
      cursor: default;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      white-space: pre;
      -webkit-user-select: none;
        -moz-user-select: none;
          -ms-user-select: none;
  }

  /* body {
    color: #000;
    padding-top: 10px;
    ${({ theme }) => theme.ipad`background: rebeccapurple`}
    ${({ theme }) => theme.superWide`background: teal`}
    ${({ theme }) => theme.wider`background: rgb(255,0,0)`}
    ${({ theme }) => theme.wide`background: rgb(0,255,0)`}
    ${({ theme }) => theme.desk`background: rgb(0,0,255)`}
    ${({ theme }) => theme.smallDesk`background: rgb(255,255,0)`}
    ${({ theme }) => theme.small`background: rgb(0,255,255)`}
    ${({ theme }) => theme.xsmall`background: rgb(255,0,255)`}
    ${({ theme }) => theme.sHeight`background: rgb(0,0,0)`}
  } */
`
