import {
  GET_HISTORY,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE
} from '../../config/constants'

export function getHistoryAction (id) {
  return {
    type: GET_HISTORY,
    tilesetId: id
  }
}

export function getHistorySuccessAction (data) {
  return {
    type: GET_HISTORY_SUCCESS,
    history: data
  }
}

export function getHistoryFailureAction (id) {
  return {
    type: GET_HISTORY_FAILURE,
    id
  }
}
