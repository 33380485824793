import { css } from 'styled-components'
export const infoHeadline = css`font: 800 6.9rem Delivery, sans-serif;`
export const infoCopy = css`font: 500 3.7333333333333334rem Delivery, sans-serif;`
export const filterSelected = css`
  font: 800 3.2rem Delivery, sans-serif;
  ${({ theme }) => theme.small`font-size: 2.4rem;`};
`
export const filterNormal = css`
  font: 500 3.2rem Delivery, sans-serif;
  ${({ theme }) => theme.small`font-size: 1.8rem;`};
`
export const poiListItemSub = css`
  font: italic 500 2.4rem Delivery, sans-serif;
  ${({ theme }) => theme.small`font-size: 1.4rem;`};
`
export const poiInfoHeadline = css`
  font: 800 4.9rem/4.3rem Delivery, sans-serif;
  ${({theme}) => theme.small(css`
    font-size: 3.4rem;
    line-height:2.7rem;
  `)}
`
export const poiInfoSubline = css`
  font: 500 3.2rem/3.2rem Delivery, sans-serif;
  ${({ theme }) => theme.small`font-size: 2.4rem;`};
`
export const poiInfoCopy = css`
  font: 400 3.2rem Delivery, sans-serif;
  ${({ theme }) => theme.small`font-size:2.2rem`}
`
export const OffTxt = css`
  font: 700 3.2rem Delivery, sans-serif;
  ${({ theme }) => theme.small`font-size:2.2rem`}
`
export const poiTimeTable = css`
  font: 500 3.2rem Delivery, sans-serif;
  ${({ theme }) => theme.small`font-size: 2.4rem;`};
`
export const loadingTitle = css`
  font: 700 3rem Delivery, sans-serif;
`

export default {
  infoHeadline,
  filterSelected,
  filterNormal,
  infoCopy,
  loadingTitle,
  poiTimeTable,
  poiInfoCopy,
  poiInfoSubline,
  poiInfoHeadline,
  poiListItemSub
}
