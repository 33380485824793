import {
  applyMiddleware,
  compose,
  createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers'

export default function configureStore (preloadedState) {
  const middlewares = thunkMiddleware
  // const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(middlewares)
  // const enhancers = middlewareEnhancer
  // const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  // const composedEnhancers = composeWithDevTools(middlewareEnhancer)
  const composedEnhancers = (process.env.NODE_ENV === 'development') ? composeWithDevTools(middlewareEnhancer) : compose(middlewareEnhancer)
  const store = createStore(rootReducer, preloadedState, composedEnhancers)
  return store
}
