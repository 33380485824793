import styled, { css } from 'styled-components'
import filterAll from '../../assets/images/filterAll.svg'
import { filterSelected } from '../../theme/typo'
import { filterItemsHeightDesk, filterItemsHeightMob } from '../../config/constants'
export const FilterBtn = styled.div`
  border-radius: 3px;
  background-color: ${({ isOpen, theme }) => isOpen ? theme.colors.dhlRed : theme.colors.dhlYellow};
  text-transform: uppercase;
  height: ${filterItemsHeightDesk}px;
  position: relative;
  ${({ theme }) => theme.small`height: ${filterItemsHeightMob}px`};
  color: ${({ isOpen, theme }) => isOpen ? theme.colors.dhlRed : '#000'};
  overflow: hidden;

  :hover{
    color: #fff;
  }
  label{
    height: ${filterItemsHeightDesk}px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${({ theme }) => theme.small`height: ${filterItemsHeightMob}px`};
  }
  button {
    background: url(${filterAll}) 0% 0px no-repeat;
    height: ${filterItemsHeightDesk}px;
    width: ${filterItemsHeightDesk}px;
    background-position: 0% 0px
    ${({ theme }) => theme.small`
      height: ${filterItemsHeightMob}px;
      width: ${filterItemsHeightMob}px;
      background-size: ${filterItemsHeightMob}px;
      background-position: 0% 0px
      `};

    ${({ isOpen }) => isOpen && css`
      background-position: 0% -50px
    `}
    ${({ isOpen, theme }) => isOpen && theme.small(css`
      background-position: 0% -40px
    `)}
  }
  span{
    ${filterSelected}
    padding-left: 10px;
    color: #fff;
    ${({ isOpen }) => !isOpen && css`display: none;`}
  }
`
