import axios from 'axios'
import { API_URL } from '../config/constants'
import { makeDbg } from '../utils/debug'
const dbg = makeDbg('API:Poi')

const yanaApi = axios.create({
  baseURL: API_URL
})

export async function getPois (isImp = false) {
  const url = `${API_URL}/listpoi?is_published=1&limit=0${isImp ? '&is_imported=0' : ''}`
  dbg('getting pois', url)
  const pois = await yanaApi.get(url)
  dbg('pois', pois)
  return pois.data
}

export default getPois
