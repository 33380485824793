import * as THREE from 'three'
import EarthObjects from './EarthObjects'
import TrackballControls from './controls/TrackballControls'
import * as bgFile from '../assets/textures/starbg.jpg'
import { makeVectorCoord } from '../utils/csv'

import {
  LOADING_3D_ASSETS,
  LOADING_3D_ASSETS_PROGRESS,
  LOADING_3D_ASSETS_SUCCESS,
  LOADING_3D_ASSETS_FAILURE,
  POI_DRAG_START,
  POI_DRAG_STOP,
  CHANGE_CONTROL_STATUS,
  earthRadius,
  // POIS_FOCUS_UPDATE
} from '../config/constants'

import { makeDbg } from '../utils/debug'

const dbg = makeDbg('3D:SceneManager')

export default (canvas, eventBus) => {
  const clock = new THREE.Clock()
  const screenDimensions = {
    width: canvas.width,
    height: canvas.height
  }

  const loadingManager = new THREE.LoadingManager()

  loadingManager.onStart = function (url, itemsLoaded, itemsTotal) {
    eventBus.publish(LOADING_3D_ASSETS, {currAsset: url, itemsLoaded, itemsTotal})
  }

  loadingManager.onLoad = function () {
    eventBus.publish(LOADING_3D_ASSETS_SUCCESS, true)
  }

  loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
    eventBus.publish(LOADING_3D_ASSETS_PROGRESS, {currAsset: url, itemsLoaded, itemsTotal})
  }

  loadingManager.onError = function (url) {
    eventBus.publish(LOADING_3D_ASSETS_FAILURE, {LoadingError: true, errorUrl: url})
  }

  const scene = new THREE.Scene()
  const sceneBG = new THREE.Scene()
  const earthParentGroup = new THREE.Group()
  const earthGroup = new THREE.Group()
  const renderer = buildRender(screenDimensions)
  let zoom = 0.5
  let lastZoom = 0
  const minZoomFOV = 5
  const maxZoomFOV = 80
  const minZoomEarthOffset = -earthRadius * 0.8
  const maxZoomEarthOffset = -earthRadius * 0.1
  const camera = buildCamera(screenDimensions)
  const cameraBG = buildCameraBG(screenDimensions)
  const controls = new TrackballControls(camera, renderer.domElement.parentElement.parentElement)
  controls.enableDamping = true // an animation loop is required when either damping or auto-rotation are enabled
  controls.dampingFactor = 0.0125
  controls.screenSpacePanning = false
  controls.minDistance = 400
  controls.maxDistance = 500
  controls.zoomSpeed = 0.1
  controls.maxPolarAngle = Math.PI / 2
  // controls.noZoom = true
  controls.noPan = true
  controls.attachTouch()
  let cameraPositionRefreshed = false
  camera.position.set(400, 100, -100)
  camera.lookAt(new THREE.Vector3(0, 0, 0))

  eventBus.subscribe(CHANGE_CONTROL_STATUS, arg => {
    controls.enabled = !arg
    arg ? controls.removeTouch() : controls.attachTouch()
  })

  const bgTex = new THREE.TextureLoader(loadingManager).load(bgFile)
  bgTex.wrapS = bgTex.wrapT = THREE.MirroredRepeatWrapping
  const bgTexAspect = 1.0
  sceneBG.background = bgTex

  eventBus.subscribe(POI_DRAG_START, arg => {
    // dbg('start drag')
    controls.enabled = false
    controls.removeTouch()
  })
  eventBus.subscribe(POI_DRAG_STOP, arg => {
    // dbg('stop drag')
    controls.enabled = true
    controls.attachTouch()
  })

  const earthObjects = new EarthObjects(scene, earthGroup, eventBus, loadingManager)

  earthGroup.rotation.z = -12 * THREE.Math.DEG2RAD

  earthParentGroup.add(earthGroup)
  scene.add(earthParentGroup)

  function buildRender ({ width, height }) {
    const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true })
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1
    renderer.setPixelRatio(DPR)
    renderer.setSize(width, height)
    renderer.setClearColor(0x000000)
    renderer.shadowMap.enabled = false
    renderer.autoClear = false
    renderer.gammaInput = true
    renderer.gammaOutput = true
    return renderer
  }

  function buildCamera ({ width, height }) {
    const aspectRatio = width / height
    const fieldOfView = 40
    const nearPlane = 1
    const farPlane = 5000
    const camera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane)
    for (let num = 0; num <= 4; num++) {
      camera.layers.enable(num)
    }
    return camera
  }

  function buildCameraBG ({ width, height }) {
    const cameraBG = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000)
    cameraBG.position.set(0, 0, 100)
    cameraBG.lookAt(new THREE.Vector3(0, 0, 0))
    return cameraBG
  }

  function update (time) {
    zoom = controls.update()
    const deltaTime = clock.getDelta()
    // const fade = 1 - (Math.cos(Math.PI * zoom) + 1) * 0.5
    const fade = 1 - Math.pow(1 - zoom, 4)
    const pos = new THREE.Vector3(0, lerp(minZoomEarthOffset, maxZoomEarthOffset, fade), 0)
    pos.applyQuaternion(camera.quaternion)
    earthParentGroup.position.set(pos.x, pos.y, pos.z)
    if (zoom !== lastZoom) {
      camera.fov = lerp(minZoomFOV, maxZoomFOV, zoom)
      lastZoom = zoom
      camera.updateProjectionMatrix()
    }
    renderer.clear()
    renderer.render(sceneBG, cameraBG)
    for (let num = 0; num <= 4; num++) {
      camera.layers.set(num)
      renderer.render(scene, camera)
    }
    earthObjects.update(deltaTime, camera, zoom)
  }

  function onWindowResize () {
    const { width, height } = canvas

    screenDimensions.width = width
    screenDimensions.height = height

    const aspect = width / height

    camera.aspect = aspect
    camera.updateProjectionMatrix()

    cameraBG.setViewOffset(width / 2, height / 2, width / 2, height / 2, width / -2, height / -2)

    const bgAspect = aspect / bgTexAspect
    bgTex.repeat = new THREE.Vector2(Math.min(bgAspect, 1), Math.min(1.0 / bgAspect, 1))
    bgTex.offset = new THREE.Vector2(-Math.min(bgAspect - 1, 0) / 2, -Math.min(1.0 / bgAspect - 1, 0) / 2)

    renderer.setSize(width, height)
  }

  function lerp (v0, v1, t) {
    return (1.0 - t) * v0 + t * v1
  }

  function iAmHere (lat, lon) {
    earthObjects.iAmHere(lat, lon)
    if (!cameraPositionRefreshed) {
      cameraPositionRefreshed = true
      const position = new THREE.Vector3(...makeVectorCoord(lon, lat))
      position.normalize().multiplyScalar(450)
      camera.position.set(position.x, position.y, position.z)
      camera.lookAt(new THREE.Vector3(0, 0, 0))
      dbg('Camera first time position')
    }
  }

  return {
    update,
    onWindowResize,
    iAmHere
  }
}
