import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  poiInfoHeadline
} from '../../theme/typo'
const PoiButton = styled.a`
  flex: 1;
  background: ${({theme}) => theme.colors.dhlYellow};
  margin: 0 2px;
  text-align: center;
  padding: 5px;
  ${poiInfoHeadline}
  text-decoration: none;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.dhlRed};
`

const PoiInfoLink = (props) => {
  return (
    <PoiButton
      href={props.url}
      target='_blank'
      // disabled
    >
      {props.txt}
    </PoiButton>
  )
}

PoiInfoLink.propTypes = {
  txt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

PoiInfoLink.defaultProps = {

}

export default PoiInfoLink
