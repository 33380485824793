import React from 'react'
// import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FoldCont, Span } from '../FoldCheckBox/FoldCheckBox.css'
const RadioButton = (props) => {
  function changeClose (e) {
    const val = e.target.value
    props.closeAction(false)
    props.changeAction(val)
  }
  return (
    <FoldCont>
      <Span>
        <input type="radio"
          name="radioSelect"
          value={props.id}
          id={props.id}
          onChange={changeClose}
          defaultChecked={props.id === props.currentSection}
        />
        <label htmlFor={props.id}>{props.name}</label>
      </Span>
    </FoldCont>
  )
}

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  changeAction: PropTypes.func.isRequired,
  closeAction: PropTypes.func.isRequired,
  currentSection: PropTypes.number.isRequired,
}

RadioButton.defaultProps = {
}

export default RadioButton
