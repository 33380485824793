import {
  GEOLOC_SUCCESS,
  LIST_TILESETS_SUCCESS
} from '../../config/constants.js'

const initialState = {
  dddops: false
}

const tileSetsData = (state = initialState, action) => {
  const newState = {...state}
  const newTileSets = {}
  switch (action.type) {
    case GEOLOC_SUCCESS:
      newState.isCheckingGeoloc = false
      newState.hasGeolocEnabled = true
      newState.loc = [action.long, action.lat]
      return newState

    case LIST_TILESETS_SUCCESS:
      return Object.assign(newTileSets, newState, action.data)
    default:
      return state
  }
}

export default tileSetsData
