import React from 'react'
import PropTypes from 'prop-types'
import {
  Drawer
} from './LocationComp.css'
const LocationCompMob = (props) => {
  const {
    isOpen,
    children
  } = props

  return (

    <Drawer
      onMouseEnter={props.onMouseEnterAction}
      onMouseLeave={props.onMouseLeaveAction}
      initialPose='closed'
      pose={isOpen ? 'opened' : 'closed'}
      className='drawer'
    >

      {children && children}
    </Drawer>
  )
}

LocationCompMob.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  // toggleOpen: PropTypes.func.isRequired,
  children: PropTypes.node,
  onMouseEnterAction: PropTypes.func.isRequired,
  onMouseLeaveAction: PropTypes.func.isRequired,
}

LocationCompMob.defaultProps = {

}

export default LocationCompMob
