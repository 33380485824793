import React from 'react'

import PropTypes from 'prop-types'

import checkG from '../../assets/images/check_G.svg'
import { FoldCont, Span } from './FoldCheckBox.css'
const FoldCheckBox = (props) => {
  const {id, name, checked, aux} = props
  return (
    <FoldCont>
      <Span bgI={aux}>
        <input
          type="checkbox"
          name={id}
          value={id}
          defaultChecked={checked}
          id={id}
          onClick={props.onFilterChange}
        />
        <label htmlFor={id}>{name}</label>
      </Span>
    </FoldCont>
  )
}

FoldCheckBox.propTypes = {
  // isOpen: PropTypes.bool.isRequired
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  aux: PropTypes.number.isRequired,
}

FoldCheckBox.defaultProps = {
  selectPct: checkG
}

export default FoldCheckBox
