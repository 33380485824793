import React from 'react'
import PropTypes from 'prop-types'
import basePct from '../../assets/images/lisbon.jpg'
import OpeningHours from '../OpeningHours'
import PoiInfoLink from '../PoiInfoLink'

import {API_URL} from '../../config/constants'

import {
  PoiCont,
  CloseBt,
  Pct,
  TxtCont,
  TtlCont,
  HeadLine,
  CopyCont,
  createMarkup,
  LinkCont } from './PoiContent.css'

const PoiContent = (props) => {
  const {
    image,
    name,
    subtitle,
    longName,
    longSubtitle,
    description,
    descTable,
    mapUrl,
    websiteUrl
  } = props.poiInfo

  return (
    <PoiCont>
      <CloseBt
        isOpen={props.isOpen}
        onClick={() => { props.closeAct() }}
      />
      {props.poiInfo
        ? (
          <div>
            {image && <Pct src={`${API_URL}/image/${image}`} alt={props.alt} />}
            <TxtCont>
              <TtlCont>
                <HeadLine>{longName || name}</HeadLine>
                <span>{longSubtitle || subtitle}</span>
              </TtlCont>
              {description && <CopyCont dangerouslySetInnerHTML={createMarkup(description)} />}
              {descTable && <OpeningHours {...descTable} />}
            </TxtCont>
            {(mapUrl || websiteUrl) && <LinkCont>
              {mapUrl && <PoiInfoLink url={mapUrl} txt='Map url' />}
              {websiteUrl && <PoiInfoLink url={websiteUrl} txt='Web url' />}
            </LinkCont>}
          </div>)
        : (<p>Loading</p>)
      }

    </PoiCont>
  )
}

PoiContent.propTypes = {
  alt: PropTypes.string.isRequired,
  closeAct: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  poiInfo: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.object.isRequired,
  ]),
}

PoiContent.defaultProps = {
  pct: basePct,
  alt: 'default picture',
  subTitle: 'this is a subTitle',
  htmlTxt: '<h2>A heading</h2><p>a paragraph, string rendered from html</p><h2>A heading</h2><p>a paragraph, string rendered from html</p><h2>A heading</h2><p>a paragraph, string rendered from html</p><h2>A heading</h2><p>a paragraph, string rendered from html</p><h2>A heading</h2><p>a paragraph, string rendered from html</p><h2>A heading</h2><p>a paragraph, string rendered from html</p><h2>A heading</h2><p>a paragraph, string rendered from html</p><h2>A heading</h2><p>a paragraph, string rendered from html</p><h2>A heading</h2><p>a paragraph, string rendered from html</p>',
  externalLink: 'https://catsandsolenoids.eu',
  geoLink: 'https://www.google.com/maps/@48.8327876,2.4167243,18z',

}

export default PoiContent
