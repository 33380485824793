import axios from 'axios'
import { API_URL } from '../config/constants'
import { parseHistory } from '../utils/csv'
import { makeDbg } from '../utils/debug'
const dbg = makeDbg('API:History')

const histApi = axios.create({
  baseURL: API_URL
})

export async function getHistAsync (tId, id, timeInterval = 300) {
  // only 300 and 900 are valid
  if (timeInterval !== 300 || timeInterval !== 900) timeInterval = 300
  let url = `/history/${tId}/${timeInterval}`
  if (id) url = url + `.${id}`
  dbg('get', url)
  try {
    dbg('start loading history')
    const histData = await histApi.get(url)
    dbg('start parse history')
    const parsedTileSet = {}
    parsedTileSet[tId] = {data: parseHistory(histData.data), id: id}
    dbg('stop parse history')
    return parsedTileSet
  } catch (error) {
    console.error(error)
    return error
  }
}

export default getHistAsync
