import axios from 'axios'
import { API_URL } from '../config/constants'
import { sortTileSets } from '../utils/tileUtils'

import {makeDbg} from '../utils/debug'
const dbg = makeDbg('API:Tilesets')

export async function getTileSets (name = null, cb, cbe) {
  dbg('start tilesets')
  try {
    const tileData = await axios.get(`${API_URL}/listtilesets`, {
      params: {
        name: name
      }
    })
    dbg('parse tilesets')
    const ts = sortTileSets(tileData.data)
    dbg('tilesets parsed')
    return ts
  } catch (error) {
    console.error(error)
    return error
  }
}

export default getTileSets
