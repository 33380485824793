import React from 'react'
import styled, { css } from 'styled-components'
import posed from 'react-pose'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toggleInfoAction } from '../../store/actions/infoActions'

import infoBg from '../../assets/images/info_0.svg'
import infoBgH from '../../assets/images/info_1.svg'
import infoBgA from '../../assets/images/info_2b.svg'

import liveBg from '../../assets/images/live_0.svg'
import liveBgH from '../../assets/images/live_1.svg'
import liveBgA from '../../assets/images/live_2b.svg'

import specialBg from '../../assets/images/special_0.svg'
import specialBgH from '../../assets/images/special_1.svg'
import specialBgA from '../../assets/images/special_2b.svg'

import fixedBg from '../../assets/images/fixed_0.svg'
import fixedBgH from '../../assets/images/fixed_1.svg'
import fixedBgA from '../../assets/images/fixed_2b.svg'

const NavBt = posed(styled.div`
  ${({ theme }) => theme.small`
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding: 5px;
  `}
  a, button{
    margin: 20px 10px;
    display: inline-block;
    width: 120px;
    height: 120px;
    ${({ theme }) => theme.small`width: 70px; height:70px`}
    background: #fecd10 no-repeat 100%/100%;
    border-radius:2px;
    ${({ theme }) => theme.small`
      position: absolute;
      top:2px;
      bottom:2px;
      left:2px;
      right:2px;
      margin: 0;
      width: calc(100% - 5px);
      height:auto;
    `}
    ${({infoBt}) => infoBt && css`
      background-image: url(${infoBg});
      :hover{
        background-image: url(${infoBgH});
        cursor: pointer;
      }
      &.active{
        background-image: url(${infoBgA});
        background-color: ${({theme}) => theme.colors.dhlRed};
      }
    `}

    ${({live}) => live && css`
      background-image: url(${liveBg});
      :hover{
        background-image: url(${liveBgH});
      }
      &.active{
        background-image: url(${liveBgA});
        background-color: ${({theme}) => theme.colors.dhlRed};
      }
    `}

    ${({special}) => special && css`
      background-image: url(${specialBg});
      :hover{
        background-image: url(${specialBgH});
      }
      &.active{
        background-image: url(${specialBgA});
        background-color: ${({theme}) => theme.colors.dhlRed};
      }
    `}

    ${({fixed}) => fixed && css`
      background-image: url(${fixedBg});
      :hover{
        background-image: url(${fixedBgH});
      }
      &.active{
        background-image: url(${fixedBgA});
        background-color: ${({theme}) => theme.colors.dhlRed};
      }
    `}
    &.active{
      pointer-events: none;
    }
  }
`)({
  enter: {
    y: '0%'
  },
  exit: {
    y: '100%'
  }
})

const Square = styled.div`
  position: relative;
  ${({ theme }) => theme.small`
    height: 0;
    width: 25%;
    padding-bottom: 25%;
  `}
`

const LiveBtmNavCont = styled.div`
  position: absolute;
  z-index: 18;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: #fff; */
  color: #000;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.small`
    justify-content: space-between;
    margin: 0 30px 10px;
  `}
`

const LiveBtmNav = (props) => {
  return (
    // <LiveBtmNavCont>
    //   <NavBt live>
    //     <NavLink exact to='/' />
    //   </NavBt>
    //   <NavBt special>
    //     <NavLink exact to='/locations' />
    //   </NavBt>
    //   <NavBt special>
    //     <NavLink exact to='/locations' />
    //   </NavBt>
    //   <NavBt infoBt>
    //     <button onClick={props.toggleInfo} />
    //   </NavBt>
    // </LiveBtmNavCont>
    <LiveBtmNavCont>
      <Square>
        <NavBt live>
          <NavLink exact to='/' />
        </NavBt>
      </Square>
      <Square>
        <NavBt fixed>
          <NavLink exact to='/fixed' />
        </NavBt>
      </Square>
      <Square>
        <NavBt special>
          <NavLink exact to='/locations' />
        </NavBt>
      </Square>
      <Square>
        <NavBt infoBt>
          <button onClick={props.toggleInfo} className={props.isHelpOpened ? 'active' : ''} />
        </NavBt>
      </Square>
    </LiveBtmNavCont>
  )
}

LiveBtmNav.propTypes = {
  toggleInfo: PropTypes.func.isRequired,
  isHelpOpened: PropTypes.bool.isRequired,
}

LiveBtmNav.defaultProps = {

}

const mapDispatchToProps = dispatch => {
  return {
    toggleInfo: () => dispatch(toggleInfoAction()),
  }
}

export default connect(null, mapDispatchToProps)(LiveBtmNav)
