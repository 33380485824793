import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css'
import App from './App'
import Media from 'react-media'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import LandWarn from './components/LandscapeWarning'
const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <Media query={{orientation: 'landscape', pointer: 'coarse'}}>
      {matches =>
        matches ? <LandWarn /> : <App />
      }
    </Media>

  </Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
