import React, { useState } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import FilterButton from '../FilterButton'
import FoldCheckBox from '../FoldCheckBox'

// import { easeIntrans } from '../../config/constants'

// import checkR from '../../assets/images/check_R.svg'
// import checkY from '../../assets/images/check_Y.svg'

// import {makeDbg} from '../../utils/debug'
// const dbg = makeDbg('COMP:LiveFilters')

const FilterCont = styled.div`
  text-align: right;
  flex: 1;
  margin: 40px 50px;
  ${({ theme }) => theme.small`margin: 15px`}
`

const CheckboxesCont = posed(styled.div`
  overflow: hidden;
`)({
  open: {
    height: 'auto'
  },
  closed: {
    height: 0
  }
})

const LiveFilter = (props) => {
  const [isOpen, toggleOpen] = useState(false)
  function tog () {
    toggleOpen(!isOpen)
  }

  return (
    <FilterCont>
      <FilterButton togFoldAct={tog} areFiltersOpen={isOpen} />
      <CheckboxesCont
        initialPose='closed'
        pose={isOpen ? 'open' : 'closed'}
      >
        {props.filters.map((filter, i) => <FoldCheckBox isOpen={isOpen} key={filter.name} {...filter} onFilterChange={props.onFilterChange} />)}
      </CheckboxesCont>
    </FilterCont>
  )
}

LiveFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
}

export default LiveFilter
