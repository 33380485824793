export default {
  white: '#fff',
  black: '#000',
  bgGrey: '#636363',
  filterBgGrey: '#4f4f4f',
  dhlYellow: '#fecd0f',
  dhlRed: '#d40411',
  poiInfoBg: '#151217',
  poiBg: '#e3e3e3',
  labelBg: '#353535'
}
