import React from 'react'
import { ThemeProvider } from 'styled-components'
import media from './media'
import PropTypes from 'prop-types'
import colors from './colors'

const Theme = ({ children }) => (
  <ThemeProvider
    theme={{
      ...media,
      colors,
      debug: false
    }}>
    {children}
  </ThemeProvider>
)

Theme.propTypes = {
  children: PropTypes.element.isRequired
}

export default Theme
