import { css } from 'styled-components'
const sizes = {
  superWide: 1400,
  wider: 1250,
  ipad: [1024, 1366],
  wide: 1100,
  desk: 1000,
  smallDesk: 800,
  small: 600,
  xsmall: 320,
  sHeight: [600, 400]
}
export default Object.keys(sizes).reduce((acc, label) => {
  if (typeof sizes[label] === 'number') {
    acc[label] = (...args) => {
      // if (typeof acc === 'number')
      return css`
        @media (max-width: ${sizes[label]}px) {
           ${css(...args)}
        }
      `
    }
  } else if (Array.isArray(sizes[label]) && sizes[label].length === 2) {
    if (label === 'ipad') {
      acc[label] = (...args) => {
        // if (typeof acc === 'number')
        return css`
          @media only screen
          and (max-width: ${sizes[label][0]}px)
          and (max-height: ${sizes[label][1]}px) {
             ${css(...args)}
          }
        `
      }
    } else {
      acc[label] = (...args) => {
        // if (typeof acc === 'number')
        return css`
          @media only screen
          and (max-height: ${sizes[label][1]}px) {
             ${css(...args)}
          }
        `
      }
    }
  }
  return acc
}, {})
