import {
  GET_TILESETS_SUCCESS,
} from '../../config/constants'

export function getTilesetsSuccessAction (tilesets) {
  return {
    type: GET_TILESETS_SUCCESS,
    tilesets
  }
}
