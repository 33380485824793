import { combineReducers } from 'redux'
import appStatus from './appStatus'
import tileSets from './tileSets'
import pois from './pois'
import liveReducer from './liveReducer'

export default combineReducers({
  appStatus,
  tileSets,
  pois,
  live: liveReducer
})
