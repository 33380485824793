import {
  GET_TILESETS_SUCCESS,
  GET_HISTORY_SUCCESS,

} from '../../config/constants.js'

const initialState = {
  tilesets: false,
  timelapses: false
}

const live = (state = initialState, action) => {
  const newState = {...state}
  switch (action.type) {
    case GET_TILESETS_SUCCESS:
      newState.tilesets = action.tilesets
      return newState
    case GET_HISTORY_SUCCESS:

      newState.timelapses = true
      newState.hasDataErr = false
      return newState
    default:
      return state
  }
}

export default live
