import React, { Component } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { emiterPP } from '../config/propTypes'
import LiveFilters from '../components/LiveFilters'
import PlayBtn from '../components/PlayBtn'
import { DISPLAY_TIMELAPSE, CHANGE_LIVE_FILTERS, UPDATE_TIMELAPSE, UPDATE_LIVE, DISPLAY_LIVE, updateRate } from '../config/constants'
import { getAllTilsets, getAllTimelapses, getTilsets } from '../api/liveApi'
import { changeCatAction } from '../store/actions/catAction'
import { getHistorySuccessAction } from '../store/actions/historyAction'
import { getTilesetsSuccessAction } from '../store/actions/liveAction'
import { makeDbg } from '../utils/debug'

const dbg = makeDbg('ROUTE:Live')
const Container = posed(styled.div`
  color:#fff;
  display:flex;
  height:100%;
  overflow: hidden;
`)({
  enter: { staggerChildren: 50 }
})

function filterOnArr (arr, id, checked) {
  const updatedList = [...arr].map(cat => {
    if (cat.id === id) {
      cat.checked = checked
    }
    return cat.checked ? cat.id : false
  }).filter(i => i !== false)
  return updatedList
}

class Live extends Component {
  static propTypes = {
    dddlogin: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    ddddefault: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    eventBus: emiterPP,
    cats: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
    ]).isRequired,
    changeCat: PropTypes.func.isRequired,
    getTilesetsSuccess: PropTypes.func.isRequired,
    getHistorySuccess: PropTypes.func.isRequired,
    live: PropTypes.object.isRequired,
  }

  getAllTilesets = async () => {
    if (!this.props.cats || !this.props.ddddefault || !this.props.dddlogin) return false
    // const timeId = this.props.dddlogin.small.id
    const liveId = this.props.ddddefault.small.id
    dbg('GetAllTilsets', liveId)
    try {
      dbg('tilesets')
      const tilesets = await getAllTilsets(this.props.cats, liveId)
      this.props.getTilesetsSuccess(tilesets)
      this.props.eventBus.publish(UPDATE_LIVE, {data: tilesets})

      dbg(tilesets)
      dbg('timeLapses')
      const timeLapses = await getAllTimelapses(this.props.cats, liveId)
      dbg(timeLapses)
      this.props.eventBus.publish(UPDATE_TIMELAPSE, {data: timeLapses})
      this.props.getHistorySuccess()
    } catch (err) {
      console.error(err)
    }
  }

  updateTileSets = async (cats) => {
    dbg('updating live tilesets ')
    const fIds = cats.filter(cat => cat.aux === 1 || cat.aux === 2).map(cat => cat.id)
    try {
      const updatedTs = await getTilsets(fIds, this.props.ddddefault.small.id)
      dbg('updatedTs')
      dbg(updatedTs)
      this.props.eventBus.publish(UPDATE_LIVE, {data: updatedTs})
    } catch (error) {
      dbg('fatal errror: guru meditation')
    }
  }

  filterAux = (cats) => {
    const catsObj = {}
    cats.map(cat => {
      catsObj[cat.id] = cat.aux
      return cat
    })
    return catsObj
  }

  componentDidMount () {
    this.props.eventBus.publish(DISPLAY_LIVE)
    if (!this.props.ddddefault) {
      this.interval = setInterval(() => {
        if (this.props.dddlogin) {
          this.props.eventBus.publish(CHANGE_LIVE_FILTERS, {active: filterOnArr(this.props.cats, -150), aux: this.filterAux(this.props.cats)})
          this.getAllTilesets()
          this.updateTileSets(this.props.cats)
          clearInterval(this.interval)
          dbg('setup update interval')
          this.updateInterval = setInterval(() => {
            this.updateTileSets(this.props.cats)
          }, updateRate)
        }
      }, 500)
    } else {
      this.props.eventBus.publish(CHANGE_LIVE_FILTERS, {active: filterOnArr(this.props.cats, -150), aux: this.filterAux(this.props.cats)})
      this.getAllTilesets()
      this.updateInterval = setInterval(() => {
        this.updateTileSets(this.props.cats)
      }, updateRate)
    }
  }

  toggleTimeLapse = (isOn) => {
    dbg('timelapse toggle', isOn)
    this.props.eventBus.publish(DISPLAY_TIMELAPSE, isOn)
  }

  onFilterChange = (e) => {
    const id = parseInt(e.target.value)
    const checked = e.target.checked
    const updatedList = [...this.props.cats].map(cat => {
      if (cat.id === id) {
        cat.checked = checked
      }
      return cat.checked ? cat.id : false
    }).filter(i => i !== false)
    dbg(updatedList)
    this.props.eventBus.publish(CHANGE_LIVE_FILTERS, {active: updatedList, aux: this.filterAux(this.props.cats)})
    this.props.changeCat(id, checked)
  }

  componentWillUnmount () {
    dbg('clearing live intervals', this.interval, this.updateInterval)
    clearInterval(this.interval)
    clearInterval(this.updateInterval)
  }

  render () {
    return (
      <Container
        initialPose='exit'
        pose='enter'
      >
        {this.props.cats && <LiveFilters
          filters={this.props.cats}
          onFilterChange={this.onFilterChange}
        />}
        {this.props.live.timelapses && <PlayBtn tooglePlay={this.toggleTimeLapse} />}
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    dddlogin: state.tileSets.dddlogin,
    ddddefault: state.tileSets.ddddefault,
    cats: state.appStatus.cats,
    live: state.live
  }
}
const mapDispatchToProps = dispatch => {
  return {
    changeCat: (id, checked) => dispatch(changeCatAction(id, checked)),
    getTilesetsSuccess: (ts) => dispatch(getTilesetsSuccessAction(ts)),
    getHistorySuccess: (hs) => dispatch(getHistorySuccessAction(hs)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Live)
