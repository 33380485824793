import React, { Component } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { emiterPP } from '../config/propTypes'
import { connect } from 'react-redux'

import { find } from 'lodash-es'

import { setFixedRadioIdAction } from '../store/actions/appActions'

import RadioFilter from '../components/RadioFilter'
import { getStat } from '../api/statistics'

import { DISPLAY_TILESETS } from '../config/constants'

import FixedSetLabel from '../components/FixedSetLabel'

const Container = posed(styled.div`
  color:#fff;
  font-size: 3rem;
  display:flex;
  height:100%;
  overflow: hidden;
`)({
  enter: { staggerChildren: 50 }
})

const LabelsCont = styled.div`
 position: absolute;
 top: 80px;
 left: 20px;
`

class Fixed extends Component {
  static propTypes = {
    eventBus: emiterPP,
    dddops: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    setFixedRadioId: PropTypes.func.isRequired,
    currFixedId: PropTypes.number.isRequired,
  }

  componentDidMount () {
    if (!this.props.dddops) {
      this.interval = setInterval(() => {
        if (this.props.dddops) {
          const currFilter = find(this.props.dddops.setsIds, o => o.id === this.props.currFixedId)
          this.loadSets(currFilter.subsets)
          clearInterval(this.interval)
        }
      }, 500)
    } else {
      const currFilter = find(this.props.dddops.setsIds, o => o.id === this.props.currFixedId)
      this.loadSets(currFilter.subsets)
    }
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  statSuccess = (data, id) => {
    this.props.eventBus.publish(DISPLAY_TILESETS, {data: data})
  }

  loadSets = async (subsets) => {
    let stats = []
    try {
      if (subsets.length > 1) {
        const prom = subsets.map(sub => getStat(this.props.dddops.id, sub.id))

        Promise.all(prom).then((values) => {
          stats = values
          this.statSuccess(values)
        }).catch(err => { throw err })
      } else {
        stats.push(await getStat(this.props.dddops.id, subsets[0].id))
        this.statSuccess(stats)
      }
    } catch (err) {
      return err
    }
  }

  changeAction = (val) => {
    const value = parseInt(val)
    if (value === this.props.currFixedId) return
    const currFilter = find(this.props.dddops.setsIds, o => o.id === value)
    if (currFilter && currFilter.subsets) {
      this.loadSets(currFilter.subsets)
    } else {
      console.error('no tileset found for:', value)
      console.error(currFilter)
    }
    this.props.setFixedRadioId(value)
  }

  render () {
    const currSet = find(this.props.dddops.setsIds, o => o.id === this.props.currFixedId) || false
    return (
      <Container
        initialPose='exit'
        pose='enter'
      >
        {this.props.dddops &&
        <div style={{flex: 1}}>
          {currSet && <LabelsCont>
            {currSet.subsets.map((set, i) => <FixedSetLabel
              key={`label-${set.id}`}
              name={set.name}
              colorI={i % 3}
            />)}
          </LabelsCont>}
          <RadioFilter
            filters={this.props.dddops.setsIds}
            // filters={menuItems}
            currentSection={this.props.currFixedId}
            changeAction={this.changeAction} />
        </div>
        }
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    dddops: state.tileSets.dddops,
    currFixedId: state.appStatus.currFixedId,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setFixedRadioId: (id) => dispatch(setFixedRadioIdAction(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fixed)
