
import { getStat } from './statistics'
import { getHistAsync } from './historyApi'

// import {makeDbg} from '../utils/debug'
// const dbg = makeDbg('API:Live')

export async function getAllTilsets (cats, liveId) {
  const allCats = cats.map(async cat => {
    const tileset = await getStat(liveId, cat.id)
    return {data: tileset, id: cat.id}
  })
  return Promise.all(allCats)
}

export async function getAllTimelapses (cats, histDataId) {
  const allCats = cats.map(async cat => {
    const timelapse = await getHistAsync(histDataId, cat.id)
    return {data: timelapse, id: histDataId}
  })
  return Promise.all(allCats)
}

export async function getTilsets (catsIds, liveId) {
  const allCats = catsIds.map(async cat => {
    const tileset = await getStat(liveId, cat)
    return {data: tileset, id: cat}
  })
  return Promise.all(allCats)
}
