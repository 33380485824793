import styled from 'styled-components'
import close from '../../assets/images/close_0.svg'
import closeH from '../../assets/images/close_1.svg'
import { poiInfoHeadline, poiInfoSubline, poiInfoCopy } from '../../theme/typo'
export const PoiCont = styled.div`
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`
export const Pct = styled.img`
  width:600px;
  ${({ theme }) => theme.small`max-width: 100%;`}
`
export const CloseBt = styled.div`
  position: ${({ isOpen }) => isOpen ? 'fixed' : 'absolute'};
  background: url(${close}) no-repeat center right/100% 100%;
  width: 50px;
  height: 50px;
  top: 10px;
  right: 10px;
  ${({ theme }) => theme.small` width: 40px; height:40px`}
  :hover{
    background-image: url(${closeH});
  }
`
export const HeadLine = styled.h3`
  ${poiInfoHeadline}
  text-transform: uppercase;
  margin-bottom: 0;
`
export const TxtCont = styled.div`
  margin: 20px;
`
export const TtlCont = styled.div`
  color: ${({ theme }) => theme.colors.dhlYellow};
  margin-bottom: 10px;
  span {
    ${poiInfoSubline}
  }
`
export const CopyCont = styled.div`
  ${poiInfoCopy}
  margin-bottom: 20px;
`
export const LinkCont = styled.div`
  display: flex;
  margin: 0 18px 160px;
`
export function createMarkup (str) {
  return { __html: str }
}
