import axios from 'axios'
import { API_URL } from '../config/constants'
const querystring = require('querystring')

function caller (latitude = 48.8672307, longitude = 2.3412525, cb, errCb, id) {
  const args = {latitude, longitude, category: 'dddapp'}
  if (id) args['client_id'] = id
  const url = `${API_URL}/iamhere?${querystring.stringify(args)}`
  axios.post(url)
    .then(function (response) {
      const id = response.headers['x-client-id']
      if (cb) cb(id)
      return id
    })
    .catch(function (error) {
      if (errCb) errCb(error)
    })
}

export default caller
