import React from 'react'
import PropTypes from 'prop-types'

import {
  SmallOverlay
} from './LocationComp.css'
const LocationCompMob = (props) => {
  const {
    isOpen,
    children
  } = props
  props.panelOpenControll(isOpen)
  return (
    <SmallOverlay
      initialPose='closed'
      pose={isOpen ? 'opened' : 'closed'}
    >
      {children && children}
    </SmallOverlay>
  )
}

LocationCompMob.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  panelOpenControll: PropTypes.func.isRequired,
}

LocationCompMob.defaultProps = {

}

export default LocationCompMob

/* <LocationsListCont>
  <button onClick={() => toggleOpen(!isOpen)}>OpenPoi desk</button>
</LocationsListCont> */
