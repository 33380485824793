import React from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import { filterNormal, poiListItemSub } from '../../theme/typo'
const PoiListItemCont = styled.div`
  margin-bottom: 5px;
  ${({ theme }) => theme.small`margin-bottom: 5px;`};
`
const PoiListItemBt = styled.button`
  background: ${({theme}) => theme.colors.dhlYellow};
  color: ${({theme}) => theme.colors.dhlRed};
  padding: 7px 10px 7px 7px;
  ${({ theme }) => theme.small`padding: 3px 8px 3px 3px`};
  text-align: left;
  cursor: pointer;

  ${({theme, hasDetails}) => hasDetails === false && css`
    background: ${theme.colors.poiBg};
    pointer-events: none;
  `}
`
const PoiListItemActive = styled.span`
  display: inline-block;
  background: ${({theme}) => theme.colors.dhlRed};
  color: ${({theme}) => theme.colors.dhlYellow};
  padding: 7px 10px 7px 7px;
  ${({ theme }) => theme.small`padding: 3px 8px 3px 3px`};
  text-align: left;
`

const Title = styled.div`
  ${filterNormal}
`
const Desc = styled.div`
  ${poiListItemSub}
`

const PoiListItem = (props) => {
  return (
    <PoiListItemCont>
      {props.isActive
        ? <PoiListItemActive>
          <Title>{props.name}</Title>
          <Desc>{props.subtitle}</Desc>
        </PoiListItemActive>
        : <PoiListItemBt
          hasDetails={props.hasDetails}
          onClick={props.hasDetails ? props.onClickAction : props.onClickNoDetailAction}
        >
          <Title>{props.name}</Title>
          <Desc>{props.subtitle}</Desc>
        </PoiListItemBt>
      }
    </PoiListItemCont>
  )
}

PoiListItem.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
  onClickNoDetailAction: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  hasDetails: PropTypes.bool.isRequired,
}

PoiListItem.defaultProps = {
}

export default PoiListItem
