import axios from 'axios'
import { API_URL } from '../config/constants'
import { makeDbg } from '../utils/debug'
import { parse } from '../utils/csv'

const dbg = makeDbg('API:Statistics')

const yanaApi = axios.create({
  baseURL: API_URL
})

export async function getStat (tsId, id) {
  let url = `/statistics/${tsId}`
  if (id) url = url + `.${id}`
  dbg('getting stats for', url)
  try {
    const statData = await yanaApi.get(url)
    dbg('start parse stats for ', id)
    const parsed = parse(statData.data)
    dbg('done parse stats')
    return parsed
  } catch (error) {
    console.error(error)
    return error
  }
}

export default getStat
