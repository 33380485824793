import { EventBus } from 'light-event-bus'
import React, { Component } from 'react'
import posed, { PoseGroup } from 'react-pose'
import { connect } from 'react-redux'
import { BrowserRouter,
  // Link,
  Route,
  Switch } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Theme from './theme'
import GlobalStyles from './theme/global'

import {
  LOADING_3D_ASSETS,
  LOADING_3D_ASSETS_PROGRESS,
  LOADING_3D_ASSETS_SUCCESS,
  GEOLOC_SEND_UPDATE,
  CHANGE_CONTROL_STATUS,
  POI_DRAG_START,
  POI_DRAG_STOP
} from './config/constants'

import LiveBtmNav from './components/LiveBtmNav'
import Locations from './routes/Locations'
import Globe from './routes/Globe'
import Live from './routes/Live'
import Fixed from './routes/Fixed'

import Info from './components/Info'
import Focus from './components/Focus'
import AddToHome from './components/AddToHome'

import LoadingOverlay from './components/LoadingOverlay'

import {
  tAssetsProgressAction,
  tAssetsStartAction,
  tAssetsSuccessAction } from './store/actions/loaderActions'
import {
  getCatAction,
  getCatSuccessAction,
  getCatFailureAction } from './store/actions/catAction'

import {
  geolocStartAction,
  geolocSuccessAction,
  geolocFailureAction,
  updateClientIdStartAction,
  updateClientIdSuccessAction,
  updateClientIdFailureAction,
} from './store/actions/locActions'

import { toggleInfoAction } from './store/actions/infoActions'

import {
  getPoisAction,
  getPoisFailureAction
} from './store/actions/poiActions'
import {
  listTileSetsStartAction,
  listTileSetsSuccessAction,
  listTileSetsFailureAction,
} from './store/actions/tileSetActions'

import { getTileSets } from './api/listTileSetsApi'
import { listcategory } from './api/listCategoryApi'

import { parseTileSet, sortTileSets } from './utils/tileUtils'
import iamhere from './api/iamhere'
import pkg from '../package.json'

import {makeDbg} from './utils/debug'
const dbg = makeDbg('APP')

console.info(`${pkg.name} ${pkg.version}, ${process.env.NODE_ENV}`)
const FullScreenCont = styled.div`
  position: relative;
  height: 100%;
  overflow:hidden;
`

const RouteContainer = posed(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`)({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 }
})

class App extends Component {
  constructor (props) {
    super(props)
    // should be created as soon as possible
    this.eventBus = new EventBus()
  }

  state = {
    // hasLoader: process.env.NODE_ENV !== 'development',
    hasLoader: true,
    has3d: true,
    isInstallable: false
  }

  static propTypes = {
    tAssetsStart: PropTypes.func.isRequired,
    tAssetsProgress: PropTypes.func.isRequired,
    tAssetsSuccess: PropTypes.func.isRequired,
    geolocStart: PropTypes.func.isRequired,
    geolocSuccess: PropTypes.func.isRequired,
    geolocFailure: PropTypes.func.isRequired,
    listTileSetsSuccess: PropTypes.func.isRequired,
    updateClientIdStart: PropTypes.func.isRequired,
    updateClientIdSuccess: PropTypes.func.isRequired,
    updateClientIdFailure: PropTypes.func.isRequired,
    clientId: PropTypes.number,
    loc: PropTypes.array.isRequired,
    isHelpOpened: PropTypes.bool.isRequired,
    toggleInfo: PropTypes.func.isRequired,
    getCat: PropTypes.func.isRequired,
    getCatSuccess: PropTypes.func.isRequired,
    getCatFailure: PropTypes.func.isRequired,
  }

  getLoc = (id) => {
    navigator.geolocation.getCurrentPosition(
      // callback
      (pos) => {
        const {latitude, longitude} = pos.coords
        this.eventBus.publish(GEOLOC_SEND_UPDATE, [latitude, longitude])
        this.props.geolocSuccess(latitude, longitude)
        this.props.updateClientIdStart()
        iamhere(
          latitude,
          longitude,
          this.props.updateClientIdSuccess,
          this.props.updateClientIdFailure,
          id || null)
      },
      // err callback
      (err) => {
        this.props.geolocFailure(err)
      },
      // opts
      {
        timeout: 15000,
        maximumAge: 0
      }
    )
  }

  getGeoloc = () => {
    if ('geolocation' in navigator) {
      this.props.geolocStart()
      this.getLoc()
      // TODO: avoid call if loc is disabled / clear interval after x failure ?
      this.locInterval = setInterval(() => {
        if (this.props.clientId) {
          this.getLoc(this.props.clientId)
        }
      }, 30000)
    } else {
      this.props.geolocFailure()
    }
  }

  processTileSets = (tileSets) => {
    if (Array.isArray(tileSets)) {
      const parsed = tileSets.map((tileSet) => {
        return parseTileSet(tileSet)
      })
      return sortTileSets(parsed)
    } else if (tileSets === Object(tileSets)) {
      return parseTileSet(tileSets)
    } else return false
  }

  getInitialData = async () => {
    // get categories
    const cats = await this.getCatData()
    const tsList = await getTileSets()
    this.props.listTileSetsSuccess(tsList)
    return cats
  }

  getCatData = async () => {
    this.props.getCat()
    try {
      const cats = await listcategory()
      this.props.getCatSuccess(cats.map(cat => {
        cat.checked = true
        return cat
      }))
      return cats
    } catch (error) {
      this.props.getCatFailure()
      console.error(error)
      return false
    }
  }

  takeEventControll = (ctrl) => {
    this.eventBus.publish(CHANGE_CONTROL_STATUS, ctrl)
  }

  dragEnd = (e) => {
    this.eventBus.publish(POI_DRAG_STOP)
    this.eventBus.publish(CHANGE_CONTROL_STATUS, false)
  }

  dragStart = (e) => {
    this.eventBus.publish(CHANGE_CONTROL_STATUS, true)
    this.eventBus.publish(POI_DRAG_START)
  }

  init = async () => {
    // set poi target init values ...
    dbg('init set poi focus initial value')
    window.POI_FOCUS_X = 48
    window.POI_FOCUS_Y = 48

    // release 3d controll on windows blur
    window.onblur = (e) => {
      this.eventBus.publish(CHANGE_CONTROL_STATUS, true)
    }
    window.onfocus = (e) => {
      this.eventBus.publish(CHANGE_CONTROL_STATUS, false)
    }

    // get geoloc
    this.getGeoloc()

    // load the initial data stata
    this.getInitialData()

    // TODO: move get poi out to the location route
    // this.props.getPois()
    // listPoi(this.poisSuccess, this.props.getPoisFailure)
  }

  LiveComp = (props) => {
    return <Live eventBus={this.eventBus} />
  }
  LocationsComp = (props) => {
    return <Locations eventBus={this.eventBus} />
  }
  FixedComp = (props) => {
    return <Fixed eventBus={this.eventBus} />
  }

  componentDidMount () {
    // load initial data
    this.init()

    // loading events subs
    this.updateLoader = this.eventBus.subscribe(LOADING_3D_ASSETS, arg => {
      this.props.tAssetsStart()
    })
    this.updateLoaderProgress = this.eventBus.subscribe(LOADING_3D_ASSETS_PROGRESS, arg => {
      this.props.tAssetsProgress(arg.itemsLoaded, arg.itemsTotal)
    })
    this.isLocation = this.eventBus.subscribe('isLocation', arg => {
      this.setState({isLocation: arg})
    })
    this.updateLoaderSuccess = this.eventBus.subscribe(LOADING_3D_ASSETS_SUCCESS, arg => {
      this.props.tAssetsSuccess()
      if (this.props.loc[0] !== false) this.eventBus.publish(GEOLOC_SEND_UPDATE, this.props.loc)
    })
  }

  componentWillUnmount () {
    this.updateLoader.unsubscribe()
    this.updateLoaderProgress.unsubscribe()
    this.updateLoaderSuccess.unsubscribe()
    this.isLocation.unsubscribe()
  }

  render () {
    return (
      <BrowserRouter>
        <Route render={({ location }) => (
          <Theme>
            <FullScreenCont>
              <GlobalStyles />
              {this.state.hasLoader && <LoadingOverlay eventBus={this.eventBus} />}
              { this.state.has3d && <Globe eventBus={this.eventBus} />}
              {this.state.isLocation && <Focus
                dragStart={this.dragStart}
                dragEnd={this.dragEnd}
              />}
              {this.props.isInited && this.state.isInstallable && <AddToHome />}
              <PoseGroup>
                <RouteContainer key={location.key + 22}>
                  <Switch location={location}>
                    <Route
                      exact
                      path='/'
                      component={this.LiveComp}
                      key='home' />
                    <Route
                      exact
                      path='/locations'
                      component={this.LocationsComp}
                      key='locations' />
                    <Route
                      exact
                      path='/fixed'
                      component={this.FixedComp}
                      key='fixed' />
                  </Switch>
                </RouteContainer>
              </PoseGroup>
              <Info closeAct={this.props.toggleInfo}
                takeEventControll={this.takeEventControll}
              />
              <LiveBtmNav isHelpOpened={this.props.isHelpOpened} />
            </FullScreenCont>
          </Theme>
        )} />
      </BrowserRouter>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    tAssetsStart: () => dispatch(tAssetsStartAction()),
    tAssetsProgress: (loaded, total) => dispatch(tAssetsProgressAction(loaded, total)),
    tAssetsSuccess: () => dispatch(tAssetsSuccessAction()),
    geolocStart: () => dispatch(geolocStartAction()),
    geolocSuccess: (loaded, total) => dispatch(geolocSuccessAction(loaded, total)),
    geolocFailure: () => dispatch(geolocFailureAction()),
    listTileSetsStart: () => dispatch(listTileSetsStartAction()),
    listTileSetsSuccess: (data) => dispatch(listTileSetsSuccessAction(data)),
    listTileSetsFailure: () => dispatch(listTileSetsFailureAction()),
    updateClientIdStart: () => dispatch(updateClientIdStartAction()),
    updateClientIdSuccess: (loaded, total) => dispatch(updateClientIdSuccessAction(loaded, total)),
    updateClientIdFailure: () => dispatch(updateClientIdFailureAction()),
    getPois: () => dispatch(getPoisAction()),
    getPoisFailure: () => dispatch(getPoisFailureAction()),
    toggleInfo: () => dispatch(toggleInfoAction()),
    getCat: () => dispatch(getCatAction()),
    getCatSuccess: (cats) => dispatch(getCatSuccessAction(cats)),
    getCatFailure: () => dispatch(getCatFailureAction()),
  }
}

const mapStateToProps = state => {
  return {
    clientId: state.appStatus.clientId,
    loc: state.appStatus.loc,
    isHelpOpened: state.appStatus.isHelpOpened,
    isInited: state.appStatus.isInited
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
