export const API_DEV = 'https://ddd.evrbit.com/api'
export const API_PROD = 'https://www.yana-dhl.com/api'
export const API_URL = process.env.REACT_APP_DEV_API ? API_DEV : API_PROD

export const rootURl = 'https://www.yana-dhl.com'

export const LOADING_3D_ASSETS = 'LOADING_3D_ASSETS'
export const LOADING_3D_ASSETS_SUCCESS = 'LOADING_3D_ASSETS_SUCCESS'
export const LOADING_3D_ASSETS_PROGRESS = 'LOADING_3D_ASSETS_PROGRESS'
export const LOADING_3D_ASSETS_FAILURE = 'LOADING_3D_ASSETS_FAILURE'

export const LOADING_API_DATA = 'LOADING_API_DATA'
export const LOADING_API_DATA_SUCCESS = 'LOADING_API_DATA_SUCCESS'
export const LOADING_API_DATA_PROGRESS = 'LOADING_API_DATA_PROGRESS'
export const LOADING_API_DATA_FAILURE = 'LOADING_API_DATA_FAILURE'

export const GEOLOC_CHECK = 'GEOLOC_CHECK'
export const GEOLOC_SUCCESS = 'GEOLOC_SUCCESS'
export const GEOLOC_FAILURE = 'GEOLOC_FAILURE'

export const UPDATE_CLIENT_ID = 'UPDATE_CLIENT_ID'
export const UPDATE_CLIENT_ID_SUCCESS = 'UPDATE_CLIENT_ID_SUCCESS'
export const UPDATE_CLIENT_ID_FAILURE = 'UPDATE_CLIENT_ID_FAILURE'

export const LIST_TILESETS = 'LIST_TILESETS'
export const LIST_TILESETS_SUCCESS = 'LIST_TILESETS_SUCCESS'
export const LIST_TILESETS_FAILURE = 'LIST_TILESETS_FAILURE'

export const GET_TILESETS = 'GET_TILESETS'
export const GET_TILESETS_SUCCESS = 'GET_TILESETS_SUCCESS'
export const GET_TILESETS_FAILURE = 'GET_TILESETS_FAILURE'

export const GET_STATISITICS = 'GET_STATISITICS'
export const GET_STATISITICS_SUCCESS = 'GET_STATISITICS_SUCCESS'
export const GET_STATISITICS_FAILURE = 'GET_STATISITICS_FAILURE'

export const SET_FIXED_ID = 'SET_FIXED_ID'
export const SET_LIVE_CHECKBOX = 'SET_LIVE_CHECKBOX'

export const GET_CATS = 'GET_CATS'
export const GET_CATS_SUCCESS = 'GET_CATS_SUCCESS'
export const GET_CATS_FAILURE = 'GET_CATS_FAILURE'

export const CHANGE_LIVE_FILTERS = 'CHANGE_LIVE_FILTERS'

export const GET_HISTORY = 'GET_HISTORY'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE'

export const TOGGLE_INFO = 'TOGGLE_INFO'
export const OPEN_INFO = 'OPEN_INFO'
export const CLOSE_INFO = 'CLOSE_INFO'

export const DISPLAY_TILESETS = 'DISPLAY_TILESETS'

export const DISPLAY_LIVE = 'DISPLAY_LIVE'
export const UPDATE_LIVE = 'UPDATE_LIVE'

export const UPDATE_TIMELAPSE = 'UPDATE_TIMELAPSE'
export const DISPLAY_TIMELAPSE = 'DISPLAY_TIMELAPSE'
export const DISPLAY_TIMELAPSE_END = 'DISPLAY_TIMELAPSE_END'

export const DISPLAY_POI_DATA = 'DISPLAY_POI_DATA'

export const GET_POIS = 'GET_POIS'
export const GET_POIS_SUCCESS = 'GET_POIS_SUCCESS'
export const GET_POIS_FAILURE = 'GET_POIS_FAILURE'
export const POIS_FOCUS_UPDATE = 'POIS_FOCUS_UPDATE'
export const POI_SELECT = 'POI_SELECT'
export const POI_DRAG_START = 'POI_DRAG_START'
export const POI_DRAG_STOP = 'POI_DRAG_STOP'

export const CHANGE_CONTROL_STATUS = 'CHANGE_CONTROL_STATUS'

export const smallSetCount = 250
export const medSetCount = 500
export const largelSetCount = 2000

export const earthRadius = 80
export const earthRadiusOffset = 0.0

export const filterItemsHeightDesk = 50
export const filterItemsHeightMob = 40

export const mainColorArr = ['#ff0', '#f00', '#fff']
export const iAmHereColor = '#0f0'

export const GEOLOC_SEND_UPDATE = 'GEOLOC_SEND_UPDATE'

export const easeIntrans = {
  default: {
    duration: 300,
    type: 'tween',
    ease: 'easeOut' }
}

export const updateRate = 60000

export const smallDs = 250
export const medDs = 500
export const largeDs = 2000

// export const filterPadding = '5px'
