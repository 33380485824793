import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { mainColorArr } from '../../config/constants'
import { poiInfoSubline } from '../../theme/typo'
const LabelCont = styled.div`
  border: 0px solid ${({cI}) => mainColorArr[cI]};
  border-left-width: 5px;
  padding: 5px 8px 5px 10px;
  background: ${({theme}) => theme.colors.labelBg};
  margin-bottom: 5px;
  text-transform: uppercase;
  ${poiInfoSubline}
`

const FixedSetLabel = (props) => {
  return (
    <LabelCont cI={props.colorI}>
      {props.name}
    </LabelCont>
  )
}

FixedSetLabel.propTypes = {
  name: PropTypes.string.isRequired,
  colorI: PropTypes.number.isRequired,
}

FixedSetLabel.defaultProps = {

}

export default FixedSetLabel
