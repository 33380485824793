import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { OffTxt } from '../../theme/typo'
import { FilterBtn } from './FilterBtn'
// import { makeDbg } from '../../utils/debug'
// const dbg = makeDbg('COMP:FilterButton')

const FilterTitleCont = styled.div`
display: flex;
justify-content: flex-end;
${({ theme, isOpen }) => isOpen && theme.small`display: block;`};
`

const OffTxtCont = styled.div`
  ${OffTxt}
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
  text-transform: uppercase;
  ${({ theme }) => theme.small(css`
    height: 40px;
    line-height: 40px;
    display: block;
  `)};
`
const FilterButton = (props) => {
  return (
    <FilterTitleCont isOpen={props.areFiltersOpen}>
      {!props.areFiltersOpen && <OffTxtCont>{props.offTxt}</OffTxtCont>}
      <FilterBtn isOpen={props.areFiltersOpen}
        initialPose='closed'
        pose={props.areFiltersOpen ? 'open' : 'closed'}
      >
        <label>
          <span>
            {props.text}
          </span>
          <button onClick={props.togFoldAct} />
        </label>
      </FilterBtn>
    </FilterTitleCont>
  )
}

FilterButton.propTypes = {
  togFoldAct: PropTypes.func.isRequired,
  areFiltersOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  offTxt: PropTypes.string,
}

FilterButton.defaultProps = {
  text: 'Choose Filter Type'
}

export default FilterButton
