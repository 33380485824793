import {
  LOADING_3D_ASSETS,
  LOADING_3D_ASSETS_PROGRESS,
  LOADING_3D_ASSETS_SUCCESS,
} from '../../config/constants'

export function tAssetsStartAction () {
  return {
    type: LOADING_3D_ASSETS
  }
}

export function tAssetsProgressAction (itemsLoaded, itemsTotal) {
  return {
    type: LOADING_3D_ASSETS_PROGRESS,
    itemsLoaded,
    itemsTotal
  }
}

export function tAssetsSuccessAction () {
  return {
    type: LOADING_3D_ASSETS_SUCCESS
  }
}
