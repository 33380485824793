import {
  smallDs,
  medDs,
  largeDs
} from '../config/constants'
export const parseTileSet = function (tileSet) {
  if (!tileSet.specification) return false // not a tileset
  tileSet.specification = JSON.parse(tileSet.specification)
  return tileSet
}

function parseOps (ops) {
  const pOps = {
    id: ops.id,
    setsIds: []
  }
  pOps.setsIds = ops.specification.map(sec => {
    if (sec.id === null) {
      sec.id = 0
    }
    if (sec.subsets) {
      const secWSub = {
        name: sec.name,
        id: sec.id,
        subsets: []
      }
      sec.subsets.map(sub => secWSub.subsets.push({
        name: sub.name,
        id: sub.id
      }))
      return secWSub
    } else {
      return {
        name: sec.name,
        id: sec.id,
        subsets: [
          {
            name: sec.name,
            id: sec.id}
        ]
      }
    }
  })
  return pOps
}

export const sortTileSets = function (tileSets) {
  const tilesObj = {
  }

  tileSets.map(function (t) {
    const parsed = parseTileSet(t)
    if (t.name !== 'dddops') {
      const safeName = t.name !== 'default' ? t.name : 'ddddefault'
      if (!tilesObj[safeName]) {
        tilesObj[safeName] = {
          small: {},
          med: {},
          large: {}
        }
      }
      // tilesObj[t.id] = parsed
      switch (t.tile_count) {
        case smallDs:
          tilesObj[safeName].small = t
          break
        case medDs:
          tilesObj[safeName].med = t
          break
        case largeDs:
          tilesObj[safeName].large = t
          break

        default:
          throw Error('not a tileset')
      }
      // tilesObj[t.name].push(t)
    } else if (t.name === 'dddops') {
      tilesObj['dddops'] = parseOps(parsed)
    }
    return t
  })

  return tilesObj
}
