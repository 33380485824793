import React, { Component } from 'react'
import threeEntryPoint from '../threejs/threeEntryPoint'
import styled from 'styled-components'
// import PropTypes from 'prop-types'
import { emiterPP } from '../config/propTypes'
const MainCont = styled.div`
  width: 100%;
  height: 100%;
`

export default class ThreeContainer extends Component {
  static propTypes = {
    eventBus: emiterPP.isRequired,
  }
  state = {
    isInited: false,
    forceInit: false
  }
  componentDidMount () {
    if (this.state.forceInit) {
      this.scene = threeEntryPoint(this.threeRootElement, this.props.eventBus)
      return
    }
    if (this.state.isInited === false) {
      this.scene = threeEntryPoint(this.threeRootElement, this.props.eventBus)
      this.setState({isInited: true})
    }
  }

  render () {
    return (
      <MainCont ref={element => { this.threeRootElement = element }} />
    )
  }
}
