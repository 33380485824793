import {
  GET_POIS,
  GET_POIS_SUCCESS,
  GET_POIS_FAILURE
} from '../../config/constants.js'

const initialState = {
  isPoiDataReady: false,
  isGettingPois: false,
  isGetingPoiInfo: false,
  pois: [],
  isGettingPoiInfo: false,
  poiInfo: false,
  hasDataErr: false
}

const poiData = (state = initialState, action) => {
  const newState = {...state}
  switch (action.type) {
    case GET_POIS:
      newState.isGettingPois = true
      return newState
    case GET_POIS_SUCCESS:
      newState.isGettingPois = false
      newState.isPoiDataReady = true
      newState.pois = action.pois
      newState.poisThree = action.poisThree
      newState.hasDataErr = false
      return newState
    case GET_POIS_FAILURE:
      newState.isPoiDataReady = false
      newState.isGettingPois = false
      newState.hasDataErr = true
      return newState
    default:
      return state
  }
}

export default poiData
