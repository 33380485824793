import React, { useState } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import FilterButton from '../FilterButton'
import RadioButton from '../RadioButton'
import { find } from 'lodash-es'
// import { makeDbg } from '../../utils/debug'
// const dbg = makeDbg('COMP:Radiofilter')

// import { easeIntrans } from '../../config/constants'

// import checkR from '../../assets/images/check_R.svg'
// import checkY from '../../assets/images/check_Y.svg'

const FilterCont = styled.div`
  text-align: right;
  margin: 40px 50px;
  flex: 1;
  position: absolute;
  left: 0;
  right: 0;
  ${({ theme }) => theme.small`margin: 15px;`}
`

const CheckboxesCont = posed(styled.div`
  /* background: blue; */
  /* display: flex; */
  /* flex-flow: column nowrap; */
  overflow: hidden;
`)({
  open: {
    height: 'auto'
  },
  closed: {
    height: 0
  }
})

const RadioFilter = (props) => {
  const [isOpen, toggleOpen] = useState(false)
  function tog () {
    toggleOpen(!isOpen)
  }
  const currSec = find(props.filters, f => f.id === props.currentSection && f.subsets.length > 1)
  const currName = (currSec && currSec.name) || null

  return (
    <FilterCont>

      <FilterButton togFoldAct={tog} areFiltersOpen={isOpen} text={props.text} offTxt={currName} />
      <CheckboxesCont
        initialPose='closed'
        pose={isOpen ? 'open' : 'closed'}
        // pose='open'
      >
        {props.filters && props.filters.map((filter, i) => {
          return <RadioButton
            isOpen={isOpen}
            key={filter.id}
            {...filter}
            currentSection={props.currentSection}
            changeAction={props.changeAction}
            closeAction={toggleOpen}
          />
        })}
      </CheckboxesCont>
    </FilterCont>
  )
}

RadioFilter.propTypes = {
  filters: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.bool.isRequired,
  ]),
  currentSection: PropTypes.number.isRequired,
  text: PropTypes.string,
  changeAction: PropTypes.func.isRequired,
}

RadioFilter.defaultProps = {
  filters: [
    {
      name: 'Directeeee Hours',
      id: 1
    },
    {
      name: 'Indirect Hours',
      id: 2
    },
    {
      name: 'Volume',
      id: 3
    },
    {
      name: 'Employees',
      id: 4
    },
    {
      name: 'CO2 Consumption Sites',
      id: 5
    },
    {
      name: 'Emissions',
      id: 6
    }
  ]
}

export default RadioFilter
