import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import PropTypes from 'prop-types'

const CtaInstall = posed(styled.button`
  position: absolute;
  top:10px;
  left:10px;
  z-index: 20;
`)({
  in: {
    x: 0
  },
  out: { x: '-150%' }
})

const AddToHome = (props) => {
  const [isDisplayed, display] = useState(false)
  const [deferredPrompt, setPrompt] = useState(false)
  const clickPrompt = () => {
    if (!deferredPrompt) {
      console.log('event not ready')
      return false
    } else {
      console.log(deferredPrompt)
      deferredPrompt.prompt()
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt')
        } else {
          console.log('User dismissed the A2HS prompt')
        }
        setPrompt(false)
      })
    }
  }
  useEffect(() => {
    const timeI = setTimeout(() => {
      display(true)
      setTimeout(() => {
        display(false)
      }, 10000)
    }, 10000)

    const installSub = window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      setPrompt(e)
    })
    return function cleanup () {
      window.removeEventListener('beforeinstallprompt', installSub)
      clearTimeout(timeI)
    }
  })

  return (
    <CtaInstall
      initialPose={'out'}
      pose={isDisplayed && deferredPrompt ? 'in' : 'out'}
      onClick={clickPrompt}>
      add to home
    </CtaInstall>
  )
}

export default AddToHome
