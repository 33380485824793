import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'
import { connect } from 'react-redux'
import { emiterPP } from '../config/propTypes'
import PoiContent from '../components/PoiContent'
import PoiListItem from '../components/PoiListItem'
import {
  DISPLAY_POI_DATA,
  CHANGE_CONTROL_STATUS,
  POIS_FOCUS_UPDATE,
  POI_SELECT
} from '../config/constants'
import { find } from 'lodash-es'
import {
  Container,
  LocationsListCont
} from '../components/LocationComp/LocationComp.css'
import LocationCompMob from '../components/LocationComp/LocationCompMob'
import LocationCompDesk from '../components/LocationComp/LocationCompDesk'
import { getPois } from '../api/listPoi'
import {makeDbg} from '../utils/debug'

import { getPoisSuccessAction } from '../store/actions/poiActions'

const dbg = makeDbg('ROUTE:Location')

export function FilterVisiblePois (pois, list) {
  const filtered = list.map(item => find(pois, o => o.id === item))
  return filtered
}

class Locations extends Component {
  state = {
    isOpen: false,
    poiInfo: false,
    currPoi: false,
    focusedPois: false
  }

  static propTypes = {
    eventBus: emiterPP,
    poisThree: PropTypes.array,
    pois: PropTypes.array,
    isPoiDataReady: PropTypes.bool.isRequired,
    getPoisSuccess: PropTypes.func.isRequired,
  }

  getAllPois = async () => {
    try {
      const pois = await getPois()
      dbg('pois', pois)
      this.props.getPoisSuccess(pois)
    } catch (error) {
      console.error(error)
    }
  }

  sendDataToThree = () => {
    dbg('send data to three called', this.props.poisThree)
    this.props.eventBus.publish(DISPLAY_POI_DATA, this.props.poisThree)
  }

  onMouseOverDrawer = (e) => {
    this.props.eventBus.publish(CHANGE_CONTROL_STATUS, true)
  }
  onMouseOutDrawer = (e) => {
    this.props.eventBus.publish(CHANGE_CONTROL_STATUS, false)
  }
  panelOpenControll = (isOpen) => {
    this.props.eventBus.publish(CHANGE_CONTROL_STATUS, isOpen)
  }
  setPanelStat = (isOpen = false) => {
    const stUp = {isOpen}
    if (isOpen === false) {
      stUp.currPoi = null
      this.props.eventBus.publish(POI_SELECT, -1)
    }
    this.setState(stUp)
  }

  openPoi = (id) => {
    const poiInfo = find(this.props.pois, o => o.id === id)
    this.setState({poiInfo, currPoi: id, isOpen: true})
    this.props.eventBus.publish(POI_SELECT, id)
  }

  componentDidMount () {
    setTimeout(() => {
    }, 200)
    if (!this.props.isPoiDataReady) {
      dbg('no poi data, start interval')
      this.getAllPois()
      this.updateInterval = setInterval(() => {
        if (this.props.isPoiDataReady) {
          this.props.eventBus.publish('isLocation', true)
          dbg('got poi data')
          this.sendDataToThree()
          clearInterval(this.updateInterval)
        } else {
          dbg('still no data')
        }
      }, 1000)
    } else {
      this.props.eventBus.publish('isLocation', true)
      this.sendDataToThree()
    }
    this.poiFocusSub = this.props.eventBus.subscribe(POIS_FOCUS_UPDATE, focList => {
      const filtered = FilterVisiblePois(this.props.pois, focList.slice(0, 4))
      this.setState({focusedPois: filtered})
    })
  }

  componentWillUnmount () {
    this.props.eventBus.publish('isLocation', false)
    this.poiFocusSub.unsubscribe()
    clearInterval(this.updateInterval)
  }

  render () {
    dbg('render')
    return (
      <Container
        initialPose='exit'
        pose='enter'
      >
        <LocationsListCont>
          {this.state.focusedPois &&
            this.state.focusedPois.map((poi, i) => {
              return (
                <PoiListItem
                  isActive={poi.id === this.state.currPoi}
                  onClickAction={() => this.openPoi(poi.id)}
                  onClickNoDetailAction={() => this.openPoi(poi.id)}
                  key={`poiItem-${i}`}
                  itemId={i}
                  {...poi}
                />
              )
            })}
        </LocationsListCont>
        <Media query="(max-width: 600px)">
          {matches =>
            matches ? (
              <LocationCompMob
                isOpen={this.state.isOpen}
                panelOpenControll={this.panelOpenControll}
              >
                <PoiContent
                  isOpen={this.state.isOpen}
                  closeAct={this.setPanelStat}
                  poiInfo={this.state.poiInfo}
                />
              </LocationCompMob>
            ) : (
              <LocationCompDesk

                isOpen={this.state.isOpen}
                toggleOpen={this.setPanelStat}
                onMouseEnterAction={this.onMouseOverDrawer}
                onMouseLeaveAction={this.onMouseOutDrawer}
              >
                <PoiContent
                  isOpen={this.state.isOpen}
                  closeAct={this.setPanelStat}
                  poiInfo={this.state.poiInfo}
                />
              </LocationCompDesk>
            )
          }
        </Media>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    pois: state.pois.pois,
    poisThree: state.pois.poisThree,
    poiInfo: state.pois.poiInfo,
    isPoiDataReady: state.pois.isPoiDataReady
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPoisSuccess: (pois) => dispatch(getPoisSuccessAction(pois)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations)
