export function parseOpening (str) {
  const lines = str.split(/\r?\n/)
  const title = lines.shift()

  const rows = lines.map(row => row.split(';'))
  const openingObj = {
    title,
    rows
  }
  return openingObj
}

export function parsePois (pois) {
  const pPois = pois.map(poi => {
    const {
      // required
      id,
      name,
      has_details,
      is_imported,
      latitude,
      longitude,
      subtitle,
      // optionals
      image,
      long_name,
      long_subtitle,
      description,
      desc_table,
      map_url,
      website_url,
    } = poi
    const pPoi = {
      id,
      name,
      hasDetails: !!has_details,
      isImported: is_imported,
      latitude,
      longitude,
      subtitle,
    }
    if (desc_table) pPoi.descTable = parseOpening(desc_table)
    if (image) pPoi.image = image
    if (long_name) pPoi.longName = long_name
    if (long_subtitle) pPoi.longSubtitle = long_subtitle
    if (description) pPoi.description = description
    if (map_url) pPoi.mapUrl = map_url
    if (website_url) pPoi.websiteUrl = website_url
    return pPoi
  })
  return pPois
}
