import styled from 'styled-components'
import checkU from '../../assets/images/check_0.svg'
import checkY from '../../assets/images/check_Y.svg'
import checkR from '../../assets/images/check_R.svg'
import checkG from '../../assets/images/check_G.svg'
import radioOn from '../../assets/images/radiobutton_0.svg'
import radioOff from '../../assets/images/radiobutton_1.svg'

import { filterSelected, filterNormal } from '../../theme/typo'


import {
  filterItemsHeightDesk,
  filterItemsHeightMob
} from '../../config/constants'

const iArr = [
  checkY,
  checkR,
  checkG
]
export const FoldCont = styled.div`
  margin-top: 5px;
  height: ${filterItemsHeightDesk}px;
  ${({ theme }) => theme.small`height: ${filterItemsHeightMob}px`};
`
export const Span = styled.span`
  background: ${({ theme }) => theme.colors.filterBgGrey};
  border-radius: 3px;
  display: inline-block;
  height: ${filterItemsHeightDesk}px;
  ${({ theme }) => theme.small`height: ${filterItemsHeightMob}px`};
  /* padding-left: 15px; */

  overflow: hidden;
  ${({ theme }) => {
    return theme.small`
    display: block;
    `
  }}

  input[type=checkbox] {
    display:none;
  }

  input[type=checkbox] + label {
    background: url(${checkU}) no-repeat center right/${filterItemsHeightDesk}px ${filterItemsHeightDesk}px;

    display: inline-block;
    padding-right: ${filterItemsHeightDesk}px;
    padding-left: 20px;
    height: 100%;
    line-height: 51px;
    ${filterNormal}
    padding-top: 5px;
    ${({ theme }) => theme.small`
      padding-top: 6px;
      padding-right: ${filterItemsHeightMob}px;
      display: block;
      background-size: ${filterItemsHeightMob}px;
      `}
  }
  input[type=checkbox]:checked + label {
    ${filterSelected}
    background-image: url(${({bgI}) => iArr[bgI - 1]});
  }
  input[type=checkbox]:active + label {
    ${filterSelected}

    background-image: url(${checkR});
  }
  input[type=radio] {
    display:none;
  }

  input[type=radio] + label {
    background: url(${radioOn}) no-repeat center right/${filterItemsHeightDesk}px ${filterItemsHeightDesk}px;
    display: inline-block;
    padding-right: ${filterItemsHeightDesk}px;
    padding-left: 20px;
    height: 100%;
    line-height: 51px;
    ${filterNormal}
    padding-top: 5px;
    ${({ theme }) => theme.small`
      padding-top: 6px;
      font-size: 2.4rem;
      padding-right: ${filterItemsHeightMob}px;
      display: block;
      background-size: ${filterItemsHeightMob}px;
    `};
  }
  input[type=radio]:checked + label {
    ${filterSelected}
    background-image: url(${radioOff});
  }
  input[type=radio]:active + label {
    ${filterSelected}
    background-image: url(${checkR});
  }
`
