import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import targetPct from '../../assets/images/focus.png'
import PropTypes from 'prop-types'

const FocusCont = styled.div`
position:absolute;
top: 0;
left: 0;
z-index: 10;
`

const FocusTarget = posed(styled.img`
  width: 97px;
  height: 97px;
  position: absolute;
  /* z-index: 15; */
`)({
  draggable: true,
})
FocusTarget.defaultProps = {
  src: targetPct
}
const Focus = (props) => {
  function updateX (x) {
    window.POI_FOCUS_X = x + 48
  }
  function updateY (y) {
    window.POI_FOCUS_Y = y + 48
  }
  return (
    <FocusCont>
      <FocusTarget
        onDragStart={props.dragStart}
        onDragEnd={props.dragEnd}
        onValueChange={{
          x: updateX,
          y: updateY,
        }}
      />
    </FocusCont>
  )
}

Focus.propTypes = {
  dragStart: PropTypes.func.isRequired,
  dragEnd: PropTypes.func.isRequired,
}

Focus.defaultProps = {

}

export default Focus
