import {
  GET_CATS,
  GET_CATS_SUCCESS,
  GET_CATS_FAILURE,
  SET_LIVE_CHECKBOX
} from '../../config/constants'

export function getCatAction (id) {
  return {
    type: GET_CATS,
    tilesetId: id
  }
}

export function getCatSuccessAction (data) {
  return {
    type: GET_CATS_SUCCESS,
    cats: data
  }
}

export function getCatFailureAction (id) {
  return {
    type: GET_CATS_FAILURE,
    id
  }
}
export function changeCatAction (id, checked) {
  return {
    type: SET_LIVE_CHECKBOX,
    id: parseInt(id),
    checked
  }
}
