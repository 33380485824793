import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import playBg from '../../assets/images/play_obj.svg'
import pauseBg from '../../assets/images/pause_obj.svg'

const PlayContOuter = styled.div`
  position: absolute;
  bottom: 40%;
  left:50%;
  width: 0;
  height: 0;
`

const PlayBt = styled.button``

const PlayContInner = styled.img`
  transform: translateX(-50%) translateY(-50%);
  width: 100px;
  height: 100px;
`

const PlayBtn = (props) => {
  const [isPlaying, toggle] = useState(false)
  function tog (isPlaying) {
    props.tooglePlay(isPlaying)
    toggle(isPlaying)
  }
  return (
    <PlayContOuter>
      <PlayBt
        onClick={() => tog(!isPlaying)}
      >
        <PlayContInner src={isPlaying ? pauseBg : playBg} />
      </PlayBt>
    </PlayContOuter>
  )
}

PlayBtn.propTypes = {
  tooglePlay: PropTypes.func.isRequired,
}

export default PlayBtn
