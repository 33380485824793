import * as THREE from 'three'
import { makeVectorCoord } from '../utils/csv'

export default (parent, vector, color, colorHover, colorSelected, extraLength, dotGlowTex, dotLightTex) => {
  const planeSize = 10
  const lightWidth = 5
  let lightHeight = 20
  let scaleLength = extraLength
  let lastLength = extraLength
  let maxOpacity = 0
  let lastOpacity = 0
  const opacitySpeed = 3.0
  let time = 0
  let isVisible = true
  let animatePulse = false
  let removeParticle = false
  let particleRemoved = false
  let removeParticleWeight = 0
  const removeParticleSpeed = 2
  const addParticleSpeed = 0.75
  let hover = false
  let selected = false
  const colorSpeed = 2.0
  let colorAnimated = true
  const currentColor = new THREE.Color(color)
  let targetColor = new THREE.Color(color)
  const defaultColor = color
  const hoverColor = colorHover
  const selectedColor = colorSelected
  const particleExtraSpeed = 2
  const pulseOffset = THREE.Math.randFloatSpread(10.0)

  let position = new THREE.Vector3(...vector)

  const dotGroup = new THREE.Group()

  const dotGlowMaterial = new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    map: dotGlowTex,
    color: defaultColor,
    blending: THREE.AdditiveBlending,
    depthFunc: THREE.LessEqualDepth,
    depthWrite: false,
    opacity: 0
  })
  const dot = new THREE.PlaneBufferGeometry(planeSize, planeSize, 1, 1)
  const dotMesh = new THREE.Mesh(dot, dotGlowMaterial)
  dotMesh.position.set(position.x, position.y, position.z)
  const pos = new THREE.Vector3(0, 0, 0)
  dotGroup.getWorldPosition(pos)
  dotMesh.lookAt(pos)
  dotMesh.layers.set(2)
  dotGroup.add(dotMesh)

  const dotLightMaterial = new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    map: dotLightTex,
    color: defaultColor,
    blending: THREE.AdditiveBlending,
    depthFunc: THREE.LessEqualDepth,
    depthWrite: false,
    opacity: 0
  })
  const lightGeo = new THREE.PlaneBufferGeometry(lightWidth, lightHeight, 1, 1)
  const lightAMesh = new THREE.Mesh(lightGeo, dotLightMaterial)
  lightAMesh.layers.set(2)
  dotMesh.add(lightAMesh)
  lightAMesh.position.set(0, 0, -lightHeight / 2)
  lightAMesh.lookAt(position)
  lightAMesh.rotateX(90 * THREE.Math.DEG2RAD)
  const lightBMesh = new THREE.Mesh(lightGeo, dotLightMaterial)
  lightBMesh.layers.set(2)
  dotMesh.add(lightBMesh)
  lightBMesh.position.set(0, 0, -lightHeight / 2)
  lightBMesh.lookAt(position)
  lightBMesh.rotateX(90 * THREE.Math.DEG2RAD)
  lightBMesh.rotateY(90 * THREE.Math.DEG2RAD)

  parent.add(dotGroup)

  function setMaxOpacity (val) {
    maxOpacity = val
  }

  function setExtraLength (newLength) {
    scaleLength = newLength
  }

  function setLatLon (lat, lon) {
    setPosition(new THREE.Vector3(...makeVectorCoord(lon, lat)))
  }

  function setPosition (vector) {
    position = vector
    dotMesh.position.set(position.x, position.y, position.z)
    const pos = new THREE.Vector3(0, 0, 0)
    dotGroup.getWorldPosition(pos)
    dotMesh.lookAt(pos)
  }

  function update (deltaTime) {
    time += deltaTime
    var fade = 1
    if (animatePulse) {
      fade = Math.sin(time + pulseOffset) * 0.125 + 0.875
    }
    lastLength = lerp(lastLength, scaleLength, deltaTime * opacitySpeed)
    if (isVisible) {
      lastOpacity = lerp(lastOpacity, maxOpacity, deltaTime * opacitySpeed)
    } else {
      lastOpacity = lerp(lastOpacity, 0, deltaTime * opacitySpeed)
    }
    fade = fade * lastOpacity
    const sca = fade * lastLength + 0.2
    if (sca > 0) {
      dotMesh.scale.set(sca, sca, sca)
    }
    if (colorAnimated) {
      currentColor.r = lerp(currentColor.r, targetColor.r, deltaTime * colorSpeed)
      currentColor.g = lerp(currentColor.g, targetColor.g, deltaTime * colorSpeed)
      currentColor.b = lerp(currentColor.b, targetColor.b, deltaTime * colorSpeed)
      dotGlowMaterial.color.set(new THREE.Color(currentColor))
      dotLightMaterial.color.set(new THREE.Color(currentColor))
    }
    dotGlowMaterial.opacity = dotLightMaterial.opacity = fade * removeParticleWeight
    if (removeParticle) {
      removeParticleWeight -= deltaTime * removeParticleSpeed + Math.sin((time + pulseOffset) * Math.PI) * deltaTime * particleExtraSpeed
      if (removeParticleWeight < 0) {
        parent.remove(dotGroup)
        particleRemoved = true
      }
    } else if (removeParticleWeight < 1) {
      removeParticleWeight += deltaTime * addParticleSpeed + Math.sin((time + pulseOffset) * Math.PI) * deltaTime * particleExtraSpeed
      if (removeParticleWeight > 1) {
        removeParticleWeight = 1
      }
    }
  }

  function remove () {
    removeParticle = true
  }

  function isParticleRemoved () {
    return particleRemoved
  }

  function setHover (val) {
    if (hover !== val) {
      hover = val
      updateMaterial()
    }
  }

  function setVisible (val) {
    isVisible = val
  }

  function setSelected (val) {
    if (selected !== val) {
      selected = val
      updateMaterial()
    }
  }

  function setColorAnimated (val) {
    colorAnimated = val
  }

  function updateMaterial () {
    if (selected) {
      targetColor = new THREE.Color(selectedColor)
    } else if (hover) {
      targetColor = new THREE.Color(hoverColor)
    } else {
      targetColor = new THREE.Color(defaultColor)
    }
  }

  function lerp (v0, v1, t) {
    return (1.0 - t) * v0 + t * v1
  }

  return {
    update,
    remove,
    isParticleRemoved,
    setHover,
    setSelected,
    setLatLon,
    setMaxOpacity,
    setExtraLength,
    setVisible,
    setColorAnimated
  }
}
