import {
  LIST_TILESETS,
  LIST_TILESETS_SUCCESS,
  LIST_TILESETS_FAILURE
} from '../../config/constants'

// import {sortTileSets} from '../../utils/tileUtils'

export function listTileSetsStartAction () {
  return {
    type: LIST_TILESETS,
    endPoint: 'listTileSets'
  }
}

export const listTileSetsSuccessAction = (data) => {
  return {
    type: LIST_TILESETS_SUCCESS,
    data: data
  }
}

export function listTileSetsFailureAction (err) {
  return {
    type: LIST_TILESETS_FAILURE,
    err,
    endPoint: 'listTileSets'
  }
}
