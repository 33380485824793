import axios from 'axios'
import { API_URL } from '../config/constants'

import {makeDbg} from '../utils/debug'
const dbg = makeDbg('API:Cats')

const yanaApi = axios.create({
  baseURL: API_URL
})

export async function listcategory () {
  try {
    dbg('start listCat')
    const statData = await yanaApi.get(`/listcategory?sort=aux`)
    const pdData = statData.data.map(cat => {
      cat.aux = parseInt(cat.aux)
      return cat
    })
    dbg('Cat list', pdData)
    return pdData
  } catch (error) {
    console.error(error)
    return error
  }
}

export default listcategory
