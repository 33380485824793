import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import posed from 'react-pose'
import { connect } from 'react-redux'

import loaderGif from '../../assets/images/loader16.gif'
import dhlLogo from '../../assets/images/dhlLogo.svg'

import { loadingTitle } from '../../theme/typo'

const LoadingOverLayCont = posed(styled.div`
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  /* background: ${({theme}) => theme.colors.dhlYellow}; */
  background: #fcca3f;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 20px;
  color: #000;
`)({
  enter: {
    y: 0
  },
  exit: {
    y: '200%'
  }
})

const TitleCont = styled.div`
  color:#000;
  font-size: 2rem;
  text-align: center;
`

const LoaderGif = styled.img`
max-width: 90%;
margin: 0 auto;
`

const Title = styled.h1`
  ${loadingTitle}
  text-align: center;
  margin-top: 50px;
  color: ${({theme}) => theme.colors.dhlRed}
  span {
    font-weight: 200;
  }
`

const LogoCont = styled.div`
  margin: 10px 40px 60px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  ${({ theme }) => theme.small(css`justify-content: center;`)};
`

const Logo = styled.img`
  min-width: 300px;
  max-width: 100%;
`

const LoadingOverLay = (props) => {
  const [isOpen, toggleOpen] = useState(true)
  const {itemsLoaded, itemsTotal, isInited} = props.appStatus
  if (isInited) {
    setTimeout(() => {
      toggleOpen(false)
    }, 5000)
  }
  return (
    <LoadingOverLayCont
      initialPose='enter'
      pose={isOpen ? 'enter' : 'exit'}
    >
      <TitleCont>
        <Title>
          WELCOME<br />
          <span>#TogetherUnstoppable!</span>
        </Title>
        <p>
          Our DHL Supply Chain world at a glance.
        </p>
        <LoaderGif src={loaderGif} />
        <p>
          {!isInited && (`loading: ${itemsLoaded} / ${itemsTotal}`)}
        </p>

      </TitleCont>
      <LogoCont>
        <Logo src={dhlLogo} />
      </LogoCont>
    </LoadingOverLayCont>
  )
}

LoadingOverLay.propTypes = {
  appStatus: PropTypes.shape({
    isInited: PropTypes.bool.isRequired,
    isLoading3d: PropTypes.bool.isRequired,
    itemsLoaded: PropTypes.number,
    itemsTotal: PropTypes.number,
  })
}

LoadingOverLay.defaultProps = {

}

const mapStateToProps = state => {
  return {
    appStatus: state.appStatus
  }
}

export default connect(mapStateToProps)(LoadingOverLay)
