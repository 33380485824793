import * as THREE from 'three'
import { makeVectorCoord } from '../utils/csv'

export default (parent, vector, color, colorHover, colorSelected, extraLength, endOffset) => {
  const rayMaxExtraLength = 1.0
  const rayMinLength = 1.01
  let maxOpacity = 0
  let lastOpacity = 0
  const opacitySpeed = 3.0
  let time = 0
  let hover = false
  let selected = false
  let isVisible = true
  let animatePulse = false
  const colorSpeed = 2.0
  const currentColor = new THREE.Color(color)
  let targetColor = new THREE.Color(color)
  let colorAnimated = true
  const defaultColor = color
  const hoverColor = colorHover
  const selectedColor = colorSelected
  let defaultOpacity = 0.25
  let hoverOpacity = 1.0
  let removeParticle = false
  let particleRemoved = false
  let removeParticleWeight = 0
  const removeParticleSpeed = 0.75
  const addParticleSpeed = 0.5
  const particleExtraSpeed = 2
  const pulseOffset = THREE.Math.randFloatSpread(10.0)

  let position = new THREE.Vector3(...vector)

  const lineMaterial = new THREE.LineBasicMaterial({
    linewidth: 1,
    color: color,
    transparent: false,
    blending: THREE.AdditiveBlending,
    fog: false,
    depthTest: true,
    depthFunc: THREE.LessEqualDepth,
    depthWrite: false,
    opacity: 0
  })
  const poss = new Float32Array(3 * 3)
  let mid = rayMinLength + extraLength * rayMaxExtraLength * 0.5
  let end = rayMinLength + extraLength * rayMaxExtraLength
  poss[0] = position.x + endOffset.x
  poss[1] = position.y + endOffset.y
  poss[2] = position.z + endOffset.z
  poss[3] = position.x * mid + endOffset.x
  poss[4] = position.y * mid + endOffset.y
  poss[5] = position.z * mid + endOffset.z
  poss[6] = position.x * end + endOffset.x
  poss[7] = position.y * end + endOffset.y
  poss[8] = position.z * end + endOffset.z
  const lineGeometry = new THREE.BufferGeometry()
  lineGeometry.addAttribute('position', new THREE.BufferAttribute(poss, 3))
  const lineMesh = new THREE.LineSegments(
    lineGeometry,
    lineMaterial
  )
  lineMesh.layers.set(3)
  parent.add(lineMesh)

  function update (deltaTime) {
    time += deltaTime
    var fade = 1
    if (animatePulse) {
      fade = Math.sin(time + pulseOffset) * 0.125 + 0.875
    }
    if (!(selected || hover)) {
      fade = fade * defaultOpacity
    } else {
      fade = fade * hoverOpacity
    }
    if (isVisible) {
      lastOpacity = lerp(lastOpacity, maxOpacity, deltaTime * opacitySpeed)
    } else {
      lastOpacity = lerp(lastOpacity, 0.0, deltaTime * opacitySpeed)
    }
    fade = fade * lastOpacity
    if (colorAnimated) {
      currentColor.r = lerp(currentColor.r, targetColor.r, deltaTime * colorSpeed)
      currentColor.g = lerp(currentColor.g, targetColor.g, deltaTime * colorSpeed)
      currentColor.b = lerp(currentColor.b, targetColor.b, deltaTime * colorSpeed)
      lineMaterial.color.set(new THREE.Color(currentColor))
    }
    lineMaterial.opacity = fade * removeParticleWeight
    if (removeParticle) {
      removeParticleWeight -= deltaTime * removeParticleSpeed + Math.sin((time + pulseOffset) * Math.PI) * deltaTime * particleExtraSpeed
      if (removeParticleWeight < 0) {
        parent.remove(lineMesh)
        particleRemoved = true
      }
    } else if (removeParticleWeight < 1) {
      removeParticleWeight += deltaTime * addParticleSpeed + Math.sin((time + pulseOffset) * Math.PI) * deltaTime * particleExtraSpeed
      if (removeParticleWeight > 0.9) {
        removeParticleWeight = 1
      }
    }
  }

  function lerp (v0, v1, t) {
    return (1.0 - t) * v0 + t * v1
  }

  function setMaxOpacity (val) {
    maxOpacity = val
  }

  function setDefaultOpacity (val) {
    defaultOpacity = val
  }

  function setHover (val) {
    hover = val
    updateMaterial()
  }

  function setSelected (val) {
    selected = val
    updateMaterial()
  }

  function setVisible (val) {
    isVisible = val
  }

  function setColorAnimated (val) {
    colorAnimated = val
  }

  function updateMaterial () {
    if (selected) {
      targetColor = new THREE.Color(selectedColor)
    } else if (hover) {
      targetColor = new THREE.Color(hoverColor)
    } else {
      targetColor = new THREE.Color(defaultColor)
    }
  }

  function setExtraLength (newLength) {
    extraLength = newLength
  }

  function setLatLon (lat, lon) {
    setPosition(new THREE.Vector3(...makeVectorCoord(lon, lat)))
  }

  function setPosition (vector) {
    position = vector
    let mid = rayMinLength + extraLength * rayMaxExtraLength * 0.5
    let end = rayMinLength + extraLength * rayMaxExtraLength
    poss[0] = position.x + endOffset.x
    poss[1] = position.y + endOffset.y
    poss[2] = position.z + endOffset.z
    poss[3] = position.x * mid + endOffset.x
    poss[4] = position.y * mid + endOffset.y
    poss[5] = position.z * mid + endOffset.z
    poss[6] = position.x * end + endOffset.x
    poss[7] = position.y * end + endOffset.y
    poss[8] = position.z * end + endOffset.z
    lineGeometry.removeAttribute('position')
    lineGeometry.addAttribute('position', new THREE.BufferAttribute(poss, 3))
  }

  function remove () {
    removeParticle = true
  }

  function isParticleRemoved () {
    return particleRemoved
  }

  return {
    update,
    remove,
    isParticleRemoved,
    setMaxOpacity,
    setDefaultOpacity,
    setHover,
    setSelected,
    setLatLon,
    setPosition,
    setExtraLength,
    setVisible,
    setColorAnimated
  }
}
