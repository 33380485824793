import {
  GET_POIS,
  GET_POIS_SUCCESS,
  GET_POIS_FAILURE,
} from '../../config/constants'

import { parsePois } from '../../utils/oHSplit'

// get pois
export function getPoisAction () {
  return {
    type: GET_POIS,
    endPoint: 'listTileSets'
  }
}

export const getPoisSuccessAction = (data) => {
  const pData = parsePois(data)
  const poisThree = data.map((poi) => {
    return {
      id: poi.id,
      hasDetails: poi.has_details,
      latitude: poi.latitude,
      longitude: poi.longitude
    }
  })

  return {
    type: GET_POIS_SUCCESS,
    pois: pData,
    poisThree
  }
}

export function getPoisFailureAction (err) {
  return {
    type: GET_POIS_FAILURE,
    err,
    endPoint: 'listPois'
  }
}
