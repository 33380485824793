import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CloseBt } from '../PoiContent/PoiContent.css'
import { easeIntrans } from '../../config/constants'
import filterIcon from '../../assets/images/filter_0.svg'
import liveIcon from '../../assets/images/live_0.svg'
import statIcon from '../../assets/images/fixed_0.svg'
import poiIcon from '../../assets/images/special_0.svg'
const InfoCont = posed(styled.div`
  color: #fff;
  background: rgba(0,0,0,.8);
  position:absolute;
  font-size:2rem;
  top: 0;
  right: 0;
  width: 600px;
  padding:20px 20px 180px 20px;
  ${({ theme }) => theme.small`width: 100%`}
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 19;
  h2 {
    color: ${({theme}) => theme.colors.dhlYellow}
  }
  a, a:visited {
    color: #fff;
  }
  p{
    line-height: 1.3;
  }
`)({
  open: {
    x: 0,
    transition: easeIntrans
  },
  closed: {
    x: '100%'
  }
})

const HeadlineCont = styled.div`
  display: flex;
  margin-top: 40px;
`
const HeadImg = styled.img`
  max-width: 80px;
  max-height: 80px;
  margin-right: 10px;
`

const Info = (props) => {
  props.takeEventControll(props.isOpened)
  return (
    <InfoCont
      initialPose='closed'
      pose={props.isOpened ? 'open' : 'closed'}
    >
      <CloseBt
        onClick={props.closeAct}
      />
      <h2>YANA - You Are Not Alone</h2>
      <p>
      This is a visualization of our DHL Supply Chain activities across the globe. Different views and filters allow you to display our footprint on a high level. Data is aggregated to city level.
      </p>
      <p>
        All data is anonymous, no personal data is stored. For information on terms & conditions and a statement on DHL&apos;s commitment to user privacy on its websites, please refer to these links:<br />
        <a href='http://www.dhl.com/en/legal.html#t_c' target='_blank' rel="noopener noreferrer">Terms & Conditions</a>
      </p>
      <p>

        <a href='/PrivacyNoticeWebsites_YANA.pdf' target={'_blank'}>Privacy Notice</a>
      </p>
      <HeadlineCont>
        <HeadImg src={filterIcon} />
        <h2>
          Filter options
        </h2>
      </HeadlineCont>
      <p>
      On the upper right-hand of your screen, a drop-down menu appears upon clicking the filter icon. Play around with it - there are some interesting displays to discover. Live data filters can be viewed simultaneously, while statistics are available one by one.
      </p>
      <HeadlineCont>
        <HeadImg src={liveIcon} />
        <h2>
          Live data & time lapse
        </h2>
      </HeadlineCont>
      <p>
        Live data:<br />
        Website: This view displays all locations from which colleagues are looking at our DHL Supply Chain world on this web application at this very point in time.<br />
        Streaming: Here you can see who of your colleagues is following the live stream from the DSC Global Conference 2019 in May.
      </p>
      <p>
        Time lapse:<br />
        24 hours in our DHL Supply Chain world - data projected onto the globe gives you a feeling for how our activity levels span across timezones. Anonymous login timestamps are used to show how our organization comes to life across the globe.
      </p>
      <HeadlineCont>
        <HeadImg src={statIcon} />
        <h2>
          DSC statistics
        </h2>
      </HeadlineCont>
      <p>
        Employees: Total headcount of DHL Supply Chain employees at each location on average in a month<br />
        Direct hours: Total numbers of direct hours at each location within a month<br />
        Indirect hours: Total numbers of indirect hours at each location within a month<br />
        Volume: Total volume handled inbound as well as outbound at each location within a month<br />
        Electricity consumption sites: kWh electricity and the thereof share of green electricity consumed in DHL-owned sites in a month<br />
        CO2 emissions: kg CO2 emissions warehousing + transportation DSC-owned facilities and vehicles in a month<br />
      </p>
      <HeadlineCont>
        <HeadImg src={poiIcon} />
        <h2>
          Our locations & POIs
        </h2>
      </HeadlineCont>
      <p>
        All our locations, be it warehouses or offices, and other points of interest (POIs) in one view. Take the round scanner from the upper left of your screen and focus onto different light beams to see:<br />
        White light beams: brief information in a grey box on the left of your screen<br />
        Red light beams: a yellow box on the left of your screen that you can click on to see more details<br />
      </p>
    </InfoCont>
  )
}

Info.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeAct: PropTypes.func.isRequired,
  takeEventControll: PropTypes.func.isRequired,
}

Info.defaultProps = {

}

const mapStateToProps = state => {
  return {
    isOpened: state.appStatus.isHelpOpened
  }
}

export default connect(mapStateToProps)(Info)
