import {
  LOADING_3D_ASSETS,
  LOADING_3D_ASSETS_PROGRESS,
  LOADING_3D_ASSETS_SUCCESS,
  TOGGLE_INFO,
  GEOLOC_CHECK,
  GEOLOC_SUCCESS,
  GEOLOC_FAILURE,
  GET_CATS,
  GET_CATS_SUCCESS,
  GET_CATS_FAILURE,
  UPDATE_CLIENT_ID,
  UPDATE_CLIENT_ID_SUCCESS,
  UPDATE_CLIENT_ID_FAILURE,
  SET_FIXED_ID,
  SET_LIVE_CHECKBOX
} from '../../config/constants.js'
// import { makeDbg } from '../../utils/debug'
// const dbg = makeDbg('RED:AppStatus')
const initialState = {
  isInited: false,
  currFixedId: 8,
  isLoading3d: false,
  itemsLoaded: 0,
  itemsTotal: 0,
  loadingSucess: false,
  isHelpOpened: false,
  hasGeolocEnabled: false,
  isCheckingGeoloc: false,
  isTrackingGeoloc: false,
  loc: [false, false],
  isGettingClientId: false,
  clientId: null,
  hasCats: false,
  isGettingCats: false,
  cats: false
}

const appStatus = (state = initialState, action) => {
  const newState = {...state}
  switch (action.type) {
    case LOADING_3D_ASSETS:
      newState.isLoading3d = true
      return newState
    case LOADING_3D_ASSETS_PROGRESS:
      newState.itemsLoaded = action.itemsLoaded
      newState.itemsTotal = action.itemsTotal
      return newState
    case SET_LIVE_CHECKBOX:
      if (Array.isArray(newState.cats)) {
        newState.cats = newState.cats.map(cat => {
          if (cat.id === action.id) {
            cat.checked = action.checked
          }
          return cat
        })
      }
      return newState
    case LOADING_3D_ASSETS_SUCCESS:
      newState.isLoading3d = false
      newState.loadingSucess = true
      newState.isInited = true
      return newState
    case TOGGLE_INFO:
      newState.isHelpOpened = !state.isHelpOpened
      return newState
    case GEOLOC_CHECK:
      newState.isCheckingGeoloc = true
      return newState
    case GEOLOC_SUCCESS:
      newState.isCheckingGeoloc = false
      newState.hasGeolocEnabled = true
      newState.loc = [action.long, action.lat]
      return newState
    case GEOLOC_FAILURE:
      newState.isCheckingGeoloc = false
      return newState
    case UPDATE_CLIENT_ID:
      newState.isGettingClientId = false
      return newState
    case UPDATE_CLIENT_ID_SUCCESS:
      newState.isGettingClientId = false
      newState.clientId = action.id
      return newState
    case UPDATE_CLIENT_ID_FAILURE:
      newState.isGettingClientId = false
      return newState
    case GET_CATS:
      newState.isGettingCats = true
      return newState
    case GET_CATS_SUCCESS:
      newState.isGettingCats = false
      newState.hasCats = true
      newState.cats = action.cats
      return newState
    case GET_CATS_FAILURE:
      newState.isGettingCats = false
      newState.hasCats = false
      return newState
    case SET_FIXED_ID:
      newState.currFixedId = action.id
      return newState
    default:
      return state
  }
}

export default appStatus
